import { Button, Checkbox, Modal, Text } from '@prev/ui-kit'
import { Row } from 'antd'
import { useContext, useState } from 'react'
import { PlanoContext } from '../../context/plano/PlanoContext'
import { termsAndConditionsText } from '../../utils/confirmModalText'
import { TermsText } from './styles'

const TermosDeUso = () => {
  const {
    precos,
    meioDePagamento,
    informacoesTrial,
    setInformacoesTrial,
    setNovaAssinatura,
    numeroDeParcelas
  } = useContext(PlanoContext)
  const [checked, setChecked] = useState(false)

  const getValue = () => {
    const value = {
      now: precos.valor,
      after: precos.valor
    }
    if (precos.proRata) {
      value.now = `${precos.proRata.valor}`
      value.after = `${precos.valor}`
    }

    return value.now
  }

  const handleAceiteTrial = () => {
    setInformacoesTrial(prev => ({
      ...prev,
      aceiteRevogacaoTrial: true,
      mostrarModalTrial: false
    }))
    const { callback } = informacoesTrial
    callback()
  }

  return (
    <Modal
      visible={
        informacoesTrial.mostrarModalTrial &&
        !informacoesTrial.aceiteRevogacaoTrial
      }
      style={{ animationDuration: '0s !important' }}
      width={700}
      footer={null}
      bodyStyle={{ padding: '30px' }}
      onCancel={() => {
        setInformacoesTrial(prev => ({ ...prev, mostrarModalTrial: false }))
        setNovaAssinatura(prev => ({
          ...prev,
          meioDePagamento: 'CARTAO_DE_CREDITO'
        }))
      }}
    >
      <Row type="flex" style={{ flexDirection: 'column' }}>
        <Text
          className={'ui-kit font-size-lg-emphasys'}
          style={{ paddingBottom: '20px' }}
        >
          Termos de uso e condições
        </Text>
        <TermsText>
          Ao prosseguir, você <b>concorda</b> com os nossos{' '}
          <a
            href="https://previdenciarista.com/termos-e-condicoes"
            target={'_blank'}
          >
            Termos de uso
          </a>{' '}
          e condições abaixo:
        </TermsText>
        <TermsText>
          - Testei o sistema profissional do Previdenciarista e desejo{' '}
          <b>encerrar meu período de experiência</b>, teste ou degustação
          ("trial") para ter acesso a funcionalidade paga;
        </TermsText>
        <TermsText>
          {termsAndConditionsText(
            precos,
            meioDePagamento,
            getValue(),
            numeroDeParcelas
          )}
        </TermsText>
        <TermsText>
          - Sou advogado(a) e/ou trabalho com a área previdenciária, tenho
          conhecimento técnico específico e, por isso, utilizo e utilizarei a
          plataforma do Previdenciarista <b>apenas para fins profissionais</b>,
          no atendimento a clientes e manutenção da minha atividade
          profissional.
        </TermsText>
        <Row>
          <Checkbox
            onChange={({ target }) => {
              setChecked(target.checked)
            }}
          />
          <TermsText>
            Declaro que li, estou ciente e aceito as condições acima
          </TermsText>
        </Row>
        <Button
          style={{
            width: '150px',
            backgroundColor: !checked ? '#EEEEEE' : '#F26526',
            borderColor: !checked ? '#EEEEEE' : '#F26526'
          }}
          onClick={handleAceiteTrial}
          disabled={!checked}
        >
          Prosseguir
        </Button>
      </Row>
    </Modal>
  )
}

export default TermosDeUso
