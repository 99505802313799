import { Text } from "@prev/ui-kit";
import styled from "styled-components";

export const TermsText = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  color: #242525;
  line-height: 22px;
  margin-bottom: 20px;
`;
