import { Row } from "antd";
import styled from "styled-components";

export const PageContainer = styled.main`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  height: 100%;
  padding: 30px;
  width: 100%;
  @media (max-width: 1023px) {
    padding: 20px;
  }
  @media (max-width: 767px) {
    padding: 10px;
  }
`;

export const FlexRowGap = styled(Row)`
  display: flex;
`;
