export const ajudasText = {
  resumoAtual: {
    planoAtual: {
      titulo: 'O que é plano atual?',
      desc: 'Esse é o estado do seu plano antes da alteração ou reativação da assinatura. É o estado em que se encontra agora, com o intervalo (mensal/anual) e os módulos. No quadro de baixo você verá o estado do seu plano após a alteração ou reativação, o seu plano futuro.'
    }
  },
  resumoNovoPlano: {
    planoFuturo: {
      titulo: 'O que é "resumo da alteração"?',
      desc: 'No bloco acima você consulta o seu plano atual, como ele está antes da alteração. <br />Aqui você consulta como ficará sua assinatura após a alteração. Caso você esteja mudando de intervalo (mensal/anual), adicionando ou removendo módulos. Tudo isso aparecerá aqui.<br />Nesse espaço também mostraremos valores que podem ser cobrados agora (pró-rata) caso a mudança no período atual acarrete aumento de valor do plano. E também o valor futuro, que será cobrado nos próximos meses ou anos, caso a assinatura não seja cancelada antes disso.'
    },
    proRata: {
      titulo: 'O que é "Pró-rata"?',
      desc: 'Esse é um valor proporcional que você deve pagar antes de alterar um plano. <br />Por exemplo, se você está saindo do plano anual e indo para o plano mensal enquanto ADICIONA um módulo novo, mas usou apenas 4 meses do seu plano anual, não lhe será cobrado nada do plano novo agora exceto o valor proporcional do NOVO módulo para os 8 meses restantes do seu plano anual atual. Após o fim do plano anual, você será cobrado o valor normal do novo plano escolhido.',
      cta: 'Saiba mais sobre o Pró-rata',
      ctaLink:
        'https://ajuda.previdenciarista.com/article/286-como-funciona-o-pagamento-pro-rata-da-assinatura'
    },
    cobradoAgora: {
      titulo: 'O que é "valor a ser cobrado agora"?',
      desc: 'Esse é o valor da cobrança que será feita agora. Sempre que há um incremento de preço no seu plano atual, haverá uma diferença a ser paga nesse momento.'
    },
    proximaCobranca: {
      titulo: 'O que é "valor futuro"?',
      desc: 'Esse será o valor que será cobrado nos próximos meses ou anos, caso a assinatura não seja cancelada antes disso. Esse valor é o valor total do plano escolhido, sem cobranças proporcionais (pró-rata) ou descontos pontuais. Após o fim do seu ciclo atual (seu mês atual ou ano atual de assinatura) a assinatura se renovará automaticamente (recorrência) nesse valor, caso não seja cancelada ou alterada antes disso.'
    }
  }
}
