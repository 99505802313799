import { Button, Modal, Text } from "@prev/ui-kit";
import { Row } from "antd";
import { useContext } from "react";
import { ResolvePendenciesContext } from "../../../context/resolverPendencias/ResolvePendenciesContext";
import { formatFns } from "../../../utils/formatFns";

const HistModal = () => {
  const { histModal, setHistModal, resume } = useContext(ResolvePendenciesContext);

  if (resume)
    return (
      <Modal
        visible={histModal}
        title={"Histórico de tentativas"}
        onOk={() => setHistModal(false)}
        onCancel={() => setHistModal(false)}
        width={600}
        height={300}
        footer={[
          <Button key="back" onClick={() => setHistModal(false)}>
            Fechar
          </Button>,
        ]}
      >
        {resume.transacoes.map((e, index) => (
          <Row
            key={`${new Date().getTime()}-${index}`}
            type="flex"
            justify="space-between"
            style={{
              marginBottom: "5px",
              textAlign: "left",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                width: "33%",
                padding: "5px",
              }}
            >
              {`${index + 1}º tentativa`}
            </Text>
            <Text style={{ width: "33%" }}>{formatFns(e.dataDeCriacao)}</Text>
            <Text
              style={{
                width: "33%",
                backgroundColor: "rgb(236, 16, 81)",
                padding: "5px",
                textAlign: "center",
              }}
              color="white"
            >
              {e.mensagem}
            </Text>
          </Row>
        ))}
      </Modal>
    );
  return null;
};

export default HistModal;
