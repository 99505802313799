import { Steps, SegmentContext, LoadingContext } from '@prev/ui-kit'
import { Col, Row } from 'antd'
import { useContext, useEffect } from 'react'
import Header from '../../../components/header/Header'
import Modulos from '../../../components/modulos/Modulos'
import Pagamento from '../../../components/pagamento/Pagamento'
import Plano from '../../../components/plano/Plano'
import Resumo from '../../../components/resumo/Resumo'
import { PlanoContext } from '../../../context/plano/PlanoContext'
import useWindowSize from '../../../hooks/useWindowSize'
import { PageContainer } from '../../../styles'
import { OverviewRow } from '../../resolverPendencias/pendencias/styles'
import AlterarPlanoSucesso from '../alterarPlanoSucesso/AlterarPlanoSucesso'
import AlterarPlanoError from '../error/AlterarPlanoError'

const AlterarPlano = () => {
  const { assinaturaAtual, step, setStep, sucessoPagamento, erroPagamento } =
    useContext(PlanoContext)
  const { width } = useWindowSize()
  const { analytics } = useContext(SegmentContext)
  const { loading } = useContext(LoadingContext)

  useEffect(() => {
    const viewport_meta = document.getElementById('viewport-meta')
    if (analytics) analytics.track('Entrou no alterar plano')
    if (viewport_meta) {
      viewport_meta.setAttribute('content', 'width=device-width')
    }
  }, [analytics])

  useEffect(() => {
    if (assinaturaAtual && assinaturaAtual.assinatura.status === 'CANCELADA') {
      window.location.href = '/plano/reativar'
    }
  }, [assinaturaAtual])

  if (!assinaturaAtual) return null

  if (sucessoPagamento) return <AlterarPlanoSucesso />

  if (erroPagamento) return <AlterarPlanoError />

  return (
    <PageContainer
      style={{ padding: '20px', height: loading ? '100px' : '100%' }}
    >
      <Header back step={step} setStep={setStep} captionOff />
      <Row type="flex">
        <OverviewRow type="flex" align="top" justify="space-between">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={15}
            xl={15}
            span={15}
            style={{ order: width > 768 ? 0 : 1 }}
          >
            <Steps
              current={step}
              initial={0}
              onChange={current => setStep(current)}
              steps={[
                {
                  title: 'Plano'
                },
                {
                  title: 'Módulos'
                },
                {
                  title: 'Selecione a forma de pagamento'
                }
              ]}
              type="default"
            />
            <br />
            {width <= 768 && <Resumo />}
            {step === 0 && <Plano />}
            {step === 1 && <Modulos />}
            {step === 2 && <Pagamento />}
          </Col>
          {width > 768 && <Resumo />}
        </OverviewRow>
      </Row>
    </PageContainer>
  )
}

export default AlterarPlano
