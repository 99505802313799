import { Button, Text } from '@prev/ui-kit'
import { Col } from 'antd'
import React, { useContext } from 'react'
import {
  BarcodeOutlined,
  CreditCardOutlined,
  QrcodeOutlined
} from '@ant-design/icons'
import { FlexRow } from './styles'
import useWindowSize from '../../../hooks/useWindowSize'
import { ResolvePendenciesContext } from '../../../context/resolverPendencias/ResolvePendenciesContext'

function PaymentMethod(props) {
  const { justifyContent, direction, showTitle, retry } = props
  const { setPaymentMethod, paymentMethod } = useContext(
    ResolvePendenciesContext
  )
  const { width } = useWindowSize()

  return (
    <Col span={24}>
      {showTitle && (
        <Text style={{ marginBottom: '10px' }} emphasys size="lg">
          Outras formas de pagamento
        </Text>
      )}
      <FlexRow
        justifycontent={justifyContent}
        gutter={[0, 16]}
        style={{ marginBottom: '30px', flexDirection: direction || 'row' }}
      >
        {retry && (
          <Button
            style={{ width: width < 1280 ? '100%' : '335px' }}
            onClick={() => {
              setPaymentMethod('CARTAO_DE_CREDITO')
            }}
            icon={<CreditCardOutlined />}
            size="large"
            color="primary"
            id="tentar-novamente-cartao"
            aria-label="Tentar novamente com cartão de crédito"
            data-testid="btn-tentar-novamente-cartao"
          >
            Tentar novamente
          </Button>
        )}
        {paymentMethod !== 'PIX' && (
          <Button
            style={{ width: width < 1280 ? '100%' : '335px' }}
            onClick={() => setPaymentMethod('PIX')}
            icon={<QrcodeOutlined />}
            size="large"
            color="secondary"
            aria-label="Selecionar pagamento via PIX"
            id="btn-metodo-pix"
            data-testid="btn-metodo-pix"
          >
            Gerar Pix
          </Button>
        )}
        {paymentMethod !== 'CARTAO_DE_CREDITO' && !retry && (
          <Button
            style={{ width: width < 1280 ? '100%' : '335px' }}
            onClick={() => setPaymentMethod('CARTAO_DE_CREDITO')}
            icon={<CreditCardOutlined />}
            size="large"
            color="secondary"
            aria-label="Selecionar pagamento via cartão de crédito"
            id="btn-metodo-cartao"
            data-testid="btn-metodo-cartao"
          >
            Cartão de crédito
          </Button>
        )}
        {paymentMethod !== 'BOLETO' && !retry && (
          <Button
            style={{ width: width < 1280 ? '100%' : '335px' }}
            onClick={() => setPaymentMethod('BOLETO')}
            icon={<BarcodeOutlined />}
            size="large"
            color="secondary"
            aria-label="Selecionar pagamento via boleto"
            id="btn-metodo-boleto"
            data-testid="btn-metodo-boleto"
          >
            Boleto bancário
          </Button>
        )}
        <Text
          style={{
            fontStyle: 'italic',
            lineHeight: '20px',
            fontSize: '14px',
            marginTop: '10px'
          }}
        >
          Prefira pagar no cartão ou no PIX para seu acesso ser liberado
          imediatamente. O pagamento no boleto pode levar até 72hs para ser
          aprovado.
        </Text>
      </FlexRow>
    </Col>
  )
}

export default PaymentMethod
