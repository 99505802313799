import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoadingProvider from "./context/LoadingContext";
import "./styles/globalstyles.css";
import { PrevPlatformProvider, SegmentProvider } from "@prev/ui-kit";
import Pendencies from "./pages/resolverPendencias/pendencias/ResolverPendencias";
import AlterarPlano from "./pages/alterarPlano/alterarPlano/AlterarPlano";
import ReativarAssinatura from "./pages/reativar/reativarAssinatura/ReativarAssinatura";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import PlanoContextProvider from "./context/plano/PlanoContext";
import ResolvePendenciesProvider from "./context/resolverPendencias/ResolvePendenciesContext";
import { AlterarParcelameno } from "./pages/alterarParcelamento/AlterarParcelamento";
import LoginModal from "./components/loginModal/LoginModal";
import LoginProvider, { LoginContext } from "./context/login/loginContext";

export default function Root() {
  useEffect(() => {
    document.getElementsByTagName('body')[0].style.backgroundColor = 'white'
  }, [])
  return (
    <div id="containerRoot">
      <PrevPlatformProvider>
        <GoogleReCaptchaProvider reCaptchaKey="6Lf0VccUAAAAANdGE1BkdpnSEnbb07RmUKLIP3KK" language="pt-BR">
          <SegmentProvider>
            <BrowserRouter basename="/plano">
              <LoadingProvider>
                <LoginProvider>
                  <LoginContext.Consumer>
                    {
                      (props) => {

                        if (!props.token) {

                          return <LoginModal />

                        } else {

                          return <Routes>
                            <Route
                              exact
                              path="/resolver"
                              element={
                                <ResolvePendenciesProvider>
                                  <Pendencies />
                                </ResolvePendenciesProvider>
                              }
                            />
                            <Route
                              exact
                              path="/alterar"
                              element={
                                <PlanoContextProvider>
                                  <AlterarPlano />
                                </PlanoContextProvider>
                              }
                            />
                            <Route
                              exact
                              path="/reativar"
                              element={
                                <PlanoContextProvider>
                                  <ReativarAssinatura />
                                </PlanoContextProvider>
                              }
                            />
                            <Route
                              exact
                              path="/parcelamento"
                              element={
                                <PlanoContextProvider>
                                  <AlterarParcelameno />
                                </PlanoContextProvider>
                              }
                            />
                          </Routes>
                        }
                      }
                    }
                  </LoginContext.Consumer>
                </LoginProvider>
              </LoadingProvider>
            </BrowserRouter>
          </SegmentProvider>
        </GoogleReCaptchaProvider>
      </PrevPlatformProvider>
    </div>
  );

}
