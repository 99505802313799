import React, { useEffect, useState, useContext } from "react";
import { Button, Text, Modal, FieldInput, SegmentContext } from '@prev/ui-kit'
import { Form, message } from 'antd'
import LogoPrevidenciarista from "@prev/ui-kit/dist/components/dataDisplay/logos/previdenciarista";
import { LoginContext } from "../../context/login/loginContext";
import { logarSemEscritorio, recuperarSenhaSemEscritorio } from "../../services/login";
import { storeToken } from "../../services/token";

const LoginModal = () => {
    const { token, setToken } = useContext(LoginContext);
    const { analytics } = useContext(SegmentContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRecuperarSenha, setIsRecuperarSenha] = useState(false);
    const [isLogin, setIsLogin] = useState(true);
    const [isRecuperarSenhaSucesso, setIsRecuperarSenhaSucesso] = useState(false);

    useEffect(() => {
        if (!token) setIsModalOpen(true);
    }, [token]);

    useEffect(() => {
        if (isRecuperarSenha) setIsLogin(false)
    }, [isRecuperarSenha])

    useEffect(() => {
        if (isLogin) setIsRecuperarSenha(false)
    }, [isLogin])

    useEffect(() => {
        const viewport_meta = document.getElementById('viewport-meta')
        if (analytics) analytics.track('Entrou no Login em Planos')
        if (viewport_meta) {
            viewport_meta.setAttribute('content', 'width=device-width')
        }
    }, [analytics])

    const handleSubmit = async values => {
        try {
            const payload = {
                username: values.reloginUsername,
                password: values.reloginPassword
            }
            const loginResponse = await logarSemEscritorio(payload)

            if (loginResponse.access_token) {
                storeToken(loginResponse.access_token);
                setToken(loginResponse.access_token)
                setIsModalOpen(false)
                if (analytics) analytics.track('Fez Login com sucesso em Planos')
            }
        } catch (err) {
            console.error(err)
            if (analytics) analytics.track('Erro ao fazer Login em Planos')
            message.error('Não foi possível realizar o login')
        }
    }

    const handleSubmitRecuperarSenha = async values => {
        try {
            const payload = {
                username: values.reloginRecuperarSenhaUsername
            }
            const recuperarSenhaResponse = await recuperarSenhaSemEscritorio(payload)

            if (recuperarSenhaResponse) {
                setIsLogin(true)
                setIsRecuperarSenha(false)
                setIsRecuperarSenhaSucesso(true)
                if (analytics) analytics.track('Pediu lembrete de senha com sucesso em Planos')
            }
        } catch (err) {
            console.error(err)
            if (analytics) analytics.track('Erro ao recuperar senha em Planos')
            message.error('Não foi possível recuperar sua senha')
        }
    }

    return (
        <Modal
            open={isModalOpen}
            width={400}
            closable={false}
            footer={null}
            wrapClassName={'modal-relogin-wrapper'}
        >
            <center>
                <LogoPrevidenciarista

                    style={{ margin: '0px 0px 20px 0px' }}
                />
            </center>

            {!isRecuperarSenha ? (
                <>
                    <Text
                        style={{ textAlign: "center" }}
                        aria-label="reloginHeader"
                        emphasys
                        id="reloginHeader"
                        size="lg"
                        type="paragraph"
                    >
                        Faça login na sua conta de proprietário do Prev
                    </Text>

                    {isRecuperarSenhaSucesso && (
                        <Text
                            style={{ textAlign: "center", color: "#00A881" }}
                            aria-label="reloginRecuperarSenhaSucesso"
                            emphasys
                            id="reloginRecuperarSenhaSucesso"
                            size="base"
                            type="paragraph"
                        >
                            Enviamos um e-mail com uma recuperação de senha para o e-mail informado, ou para o e-mail cadastrado na conta com o CPF informado. Acesse seu e-mail e gere uma nova senha. Verifique sua caixa de spam.
                        </Text>
                    )}

                    <Form style={{ width: "100%" }} onFinish={values => { handleSubmit(values); }}>
                        <Form.Item
                            name="reloginUsername"
                            style={{ margin: 0 }}
                            rules={[
                                {
                                    required: true,
                                    message: "Por favor, insira seu email ou CPF",
                                },
                            ]}
                        >
                            <FieldInput
                                id="reloginUsername"
                                aria-label="reloginUsername"
                                data-testid="input-modal-login-username"
                                size="medium"
                                label="Digite seu e-mail ou CPF"
                                placeholder="Ex: email@gmail.com ou 12345679011"
                                autoComplete="username"
                            />
                        </Form.Item>

                        <Form.Item
                            name="reloginPassword"
                            style={{ margin: "20px 0px 0px 0px" }}
                            rules={[
                                {
                                    required: true,
                                    message: "Por favor, insira sua senha",
                                },
                            ]}
                        >
                            <FieldInput.Password
                                id="reloginPassword"
                                aria-label="reloginPassword"
                                data-testid="input-modal-login-password"
                                size="medium"
                                label="Digite sua senha"
                                placeholder="Digite a senha da sua conta no Prev"
                                autoComplete="current-password"
                            />
                        </Form.Item>

                        <Text
                            style={{ margin: "8px 0px 0px 0px", color: "#3575AB" }}
                            onClick={() => setIsRecuperarSenha(true)}
                            aria-label="reloginEsqueceuASenhaLink"
                            emphasys
                            id="reloginEsqueceuASenhaLink"
                            size="base"
                            type="div"
                        >
                            Esqueceu a senha?
                        </Text>

                        <Button
                            aria-label="reloginLogar"
                            id="reloginLogar"
                            data-testid="btn-modal-relogin-logar"
                            size="large"
                            textcolor=""
                            htmlType="submit"
                            style={{
                                width: "100%",
                                margin: "40px 0px 0px 0px"
                            }}
                        >
                            Entrar
                        </Button>
                        <Button
                            aria-label="reloginCancelar"
                            color="secondary"
                            id="reloginCancelar"
                            data-testid="btn-modal-relogin-cancelar"
                            size="large"
                            textcolor=""
                            onClick={() => window.location.href = "/login"}
                            style={{
                                width: "100%",
                                margin: "20px 0px 0px 0px"
                            }}
                        >
                            Cancelar
                        </Button>
                    </Form>
                </>
            ) : (
                <>
                    <Text
                        style={{ textAlign: "center" }}
                        aria-label="reloginRecuperarSenhaHeader"
                        emphasys
                        id="reloginRecuperarSenhaHeader"
                        size="lg"
                        type="paragraph"
                    >
                        Recupere sua senha de acesso ao Prev
                    </Text>

                    <Form style={{ width: "100%" }} onFinish={values => { handleSubmitRecuperarSenha(values); }}>
                        <Form.Item
                            name="reloginRecuperarSenhaUsername"
                            style={{ margin: 0 }}
                            rules={[
                                {
                                    required: true,
                                    message: "Por favor, insira seu email ou CPF",
                                },
                            ]}
                        >
                            <FieldInput
                                id="reloginRecuperarSenhaUsername"
                                aria-label="reloginRecuperarSenhaUsername"
                                data-testid="input-modal-recuperarsenha-username"
                                size="medium"
                                label="Digite seu e-mail ou CPF"
                                placeholder="Ex: email@gmail.com ou 12345679011"
                                autoComplete="username"
                            />
                        </Form.Item>

                        <Button
                            aria-label="reloginLogar"
                            id="reloginLogar"
                            data-testid="btn-modal-relogin-logar"
                            size="large"
                            textcolor=""
                            htmlType="submit"
                            style={{
                                width: "100%",
                                margin: "20px 0px 0px 0px"
                            }}
                        >
                            Recuperar minha senha
                        </Button>
                        <Button
                            aria-label="reloginCancelar"
                            color="secondary"
                            id="reloginCancelar"
                            data-testid="btn-modal-relogin-cancelar"
                            size="large"
                            textcolor=""
                            onClick={() => setIsLogin(true)}
                            style={{
                                width: "100%",
                                margin: "20px 0px 0px 0px"
                            }}
                        >
                            Voltar
                        </Button>
                    </Form>
                </>
            )
            }

        </Modal>
    )
}


export default LoginModal