const MESES = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

export const formataDataJSX = (date) => {
  const [year, month, day] = date?.split("-");
  return (
    <b>
      {day} de {MESES[month - 1]} de {year}
    </b>
  );
};

export const formataDataComTexto = (date) => {
  const [year, month, day] = date.split("-");
  return `${day} de ${MESES[month - 1]} de ${year}`;
};
