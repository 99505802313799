import { Row } from "antd";
import styled from "styled-components";

export const CustomRow = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  column-gap: 10px;
`;
