import { formataDataComTexto, formataDataJSX } from "./formataDatas";

export const confirmModalText = (pricing, paymentMethod, assinaturaAtual, numeroDeParcelas) => {
  const values = {
    now: pricing.valor,
    after: pricing.valor,
  };
  if (pricing.proRata) {
    values.now = `${pricing.proRata.valor}`;
    values.after = `${pricing.valor}`;
  }

  const parcela = pricing?.parcelas?.find((e) => e.parcelas === numeroDeParcelas);

  const valorParcelaFormatado =
    Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(parcela?.valorDaParcela) || "";

  if (parcela) values.now = parcela.valorTotal;

  if (paymentMethod === "BOLETO")
    return `Você confirma a geração do boleto de <b data-testid='modal_confirmacao_valor'>${Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(values.now)}</b> para <b>pagamento à vista</b>, em uma <b>única parcela</b>, neste momento?`;
  if (paymentMethod === "CARTAO_DE_CREDITO") {
    return `Você confirma a cobrança de <b data-testid='modal_confirmacao_valor'>${Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(values.now)}</b> ${
      numeroDeParcelas > 1
        ? `<b>em ${numeroDeParcelas}x de ${valorParcelaFormatado} (juros de ${parcela.jurosPercentual}%)</b> em seu cartão ?`
        : `a vista em uma <b>única parcela</b>, neste momento no seu cartão ?`
    }
    <br><br>
    <p>Esta ação é irreversível.</p>`;
  }
  if (paymentMethod === "PIX") {
    return `Você confirma a geração do PIX de <b data-testid='modal_confirmacao_valor'>${Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(values.now)}</b> para <b>pagamento à vista</b>, em uma <b>única parcela</b>, neste momento?`;
  }
};

export const confirmParcelamentoText = (numeroDeParcelas, precos) => {
  const dataCobranca = formataDataComTexto(precos?.assinatura?.dataDaProximaCobranca);
  if (numeroDeParcelas === 1)
    return `Você confirma cobrança no dia <b>${dataCobranca}</b> no valor de <b>${Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(precos.valor)}</b> à vista em uma <b>única parcela</b> em seu cartão ?`;

  return `Você confirma o parcelamento da cobrança no dia <b>${dataCobranca}</b> no valor de <b>${Intl.NumberFormat(
    "pt-BR",
    {
      style: "currency",
      currency: "BRL",
    }
  ).format(
    precos.parcelas[numeroDeParcelas - 1].valorTotal
  )}</b> em <b>${numeroDeParcelas}x</b> de <b>${Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(precos.parcelas[numeroDeParcelas - 1].valorDaParcela)} (juros de 5.99%)</b> em seu cartão ?`;
};

export const termsAndConditionsText = (pricing, paymentMethod, value, numeroDeParcelas) => {
  const parcela = pricing?.parcelas?.find((e) => e.parcelas === numeroDeParcelas);

  const valorParcelaFormatado =
    Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(parcela?.valorDaParcela) || "";

  return (
    <div>
      - Estou ciente e{" "}
      <b>
        aceito a cobrança de{" "}
        {parcela && numeroDeParcelas > 1
          ? `${Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(parcela?.valorTotal)} em ${numeroDeParcelas}x de ${valorParcelaFormatado} (juros de ${
              parcela.jurosPercentual
            }%)`
          : Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(value)}
      </b>{" "}
      que será realizada neste momento e que este valor não é reembolsável;
    </div>
  );
};
