import { Steps, SegmentContext, LoadingContext } from '@prev/ui-kit'
import { Col, Row } from 'antd'
import { useContext, useEffect } from 'react'
import Header from '../../../components/header/Header'
import useWindowSize from '../../../hooks/useWindowSize'
import { PageContainer } from '../../../styles'
import { OverviewRow } from '../../resolverPendencias/pendencias/styles'
import ReativarSucesso from '../reativarSucesso/ReativarSucesso'
import ReativarError from '../error/ReativarError'
import Modulos from '../../../components/modulos/Modulos'
import Plano from '../../../components/plano/Plano'
import Resumo from '../../../components/resumo/Resumo'
import PagamentoReativar from '../../../components/pgamentoReativar/PagamentoReativar'
import { PlanoContext } from '../../../context/plano/PlanoContext'

const ReativarAssinatura = () => {
  const { assinaturaAtual, step, setStep, sucessoPagamento, erroPagamento } =
    useContext(PlanoContext)
  const { width } = useWindowSize()
  const { analytics } = useContext(SegmentContext)
  const { loading } = useContext(LoadingContext)

  useEffect(() => {
    const viewport_meta = document.getElementById('viewport-meta')
    if (analytics) analytics.track('Reativar assinatura Viewed')
    if (viewport_meta) {
      viewport_meta.setAttribute('content', 'width=device-width')
    }
  }, [analytics])

  if (!assinaturaAtual) return null

  if (sucessoPagamento) return <ReativarSucesso />

  if (erroPagamento) return <ReativarError />

  return (
    <PageContainer
      style={{ padding: '20px', height: loading ? '100vw' : '100%' }}
    >
      <Header back step={step} setStep={setStep} captionOff />
      <Row type="flex">
        <OverviewRow type="flex" align="top" justify="space-between">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={15}
            xl={15}
            span={15}
            style={{ order: width > 768 ? 0 : 1 }}
          >
            <Steps
              current={step}
              initial={0}
              onChange={current => setStep(current)}
              steps={[
                {
                  title: 'Plano'
                },
                {
                  title: 'Módulos'
                },
                {
                  title: 'Selecione a forma de pagamento'
                }
              ]}
              type="default"
            />
            <br />
            {width <= 768 && <Resumo />}
            {step === 0 && <Plano />}
            {step === 1 && <Modulos />}
            {step === 2 && <PagamentoReativar />}
          </Col>
          {width > 768 && <Resumo />}
        </OverviewRow>
      </Row>
    </PageContainer>
  )
}

export default ReativarAssinatura
