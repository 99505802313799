import { Card, Radio, Text } from "@prev/ui-kit";
import { Col, Row } from "antd";
import React, { useContext } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import useWindowSize from "../../hooks/useWindowSize";
import { findModule, prevModules } from "../../utils/prevModules";
import { formataDataJSX } from "../../utils/formataDatas";
import { PlanoContext } from "../../context/plano/PlanoContext";
import { BASE_MODULES } from "../../context/plano/usePlano";
import Swal from "sweetalert2";
import { CustomCol } from "../resolverPendencias/resumo/styles";

const modulos = {
  CALCULOS_PETICOES: "calculos",
  CATALOGO: "diretorio",
  CASOS: "casos",
};

export const ResumoParcelamento = ({ carregandoPreco }) => {
  const { width } = useWindowSize();
  const { novaAssinatura, precos, assinaturaAtual, numeroDeParcelas } = useContext(PlanoContext);

  const regraMostrarModalConfirmaPagto =
    novaAssinatura.intervalo === "ANUAL" &&
    precos &&
    (precos.proRata || moment(precos.assinatura.periodo.dataDaCobranca).isSameOrBefore(new Date()));

  const proximaDatacobranca = () => {
    if (assinaturaAtual.assinatura.trial && precos.proRata) {
      const { periodoDeAcesso } = precos.proRata;
      const fimAcesso = moment(periodoDeAcesso.fim).add(1, "day").format("YYYY-MM-DD");
      const diaCobrancaFormatada = formataDataJSX(fimAcesso);
      return diaCobrancaFormatada;
    }
    const dataFormatada = formataDataJSX(precos.assinatura.dataDaProximaCobranca);
    return dataFormatada;
  };

  if (!precos) return null;
  return (
    <CustomCol xs={24} sm={24} md={24} lg={8} xl={8} span={8}>
      <Card
        style={{
          margin: "20px 0 0px 0",
          marginBottom: width < 1024 ? "20px" : 0,
          opacity: carregandoPreco ? 0 : 1,
        }}
        data-testid="sidebar_resumo_plano_futuro"
      >
        <Col>
          <Text style={{ marginBottom: "10px" }} emphasys>
            Resumo da alteração
          </Text>
          <Text emphasys style={{ marginBottom: "5px" }}>
            Plano
          </Text>
          <Row type="flex" justify="space-between">
            <Text style={{ marginBottom: "5px" }} color="#949595" id="futuro_plano" data-testid="futuro_plano">
              {novaAssinatura.intervalo}
            </Text>
            <Text id="futuro_valor_bruto" data-testid="futuro_valor_bruto">
              {Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(findModule("CALCULO_PETICOES", precos.tabelaDePrecoModulos.precosModulos).valorBase)}
            </Text>
          </Row>
          {novaAssinatura.tiposDeModulo.filter((e) => e !== "CALCULO_PETICOES").length ? (
            <Text emphasys style={{ marginBottom: "10px" }}>
              Módulos
            </Text>
          ) : null}
          <Col span={24}>
            {novaAssinatura.tiposDeModulo
              .filter((modulo) => modulo !== "CALCULO_PETICOES")
              .map((e, i) => (
                <Row
                  key={`${i}-${e}`}
                  type="flex"
                  justify="space-between"
                  align="middle"
                  style={{
                    marginBottom: "10px",
                    rowGap: "10px",
                    columnGap: "10px",
                  }}
                >
                  <Row style={{ columnGap: "10px", rowGap: "10px" }}>{prevModules(e)}</Row>
                  <Row style={{ columnGap: "10px", rowGap: "10px" }}>
                    <Text data-testid={`futuro_modulo_${e.includes("CASOS") ? "casos" : modulos[e]}_valor`}>
                      {Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(findModule(e, precos.tabelaDePrecoModulos.precosModulos).valorBase)}
                    </Text>
                  </Row>
                </Row>
              ))}
          </Col>
          {precos.valorDoDesconto || (precos.proRata && precos.proRata.valorDoDesconto) ? (
            <>
              <Text emphasys style={{ marginBottom: "5px" }}>
                Descontos
              </Text>
              {precos && precos.valorDoDesconto && !precos.proRata ? (
                <Row type="flex" justify="space-between">
                  <Text style={{ marginBottom: "5px" }} color="#949595">
                    Desconto de 20%
                  </Text>
                  <Text
                    style={{ marginBottom: "5px" }}
                    color="#949595"
                    id="futuro_valor_desconto"
                    data-testid="futuro_valor_desconto"
                  >
                    {Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(precos.valorDoDesconto)}
                  </Text>
                </Row>
              ) : null}
              {precos.proRata && precos.proRata.valorDoDesconto > 0 && (
                <Row type="flex" justify="space-between">
                  <Text data-testid="pro-rata" style={{ marginBottom: "5px" }} color="#949595">
                    Pro rata
                  </Text>
                  <Text
                    style={{ marginBottom: "5px" }}
                    color="#949595"
                    id="futuro_desconto_prorata"
                    data-testid="futuro_desconto_prorata"
                  >
                    {Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(precos.proRata.valorDoDesconto)}
                  </Text>
                </Row>
              )}
            </>
          ) : (
            ""
          )}

          {!precos.proRata ? (
            <Row type="flex" justify="space-between">
              <Text emphasys style={{ marginBottom: "5px" }}>
                {numeroDeParcelas > 1 ? "Total parcelado" : "Total"}
              </Text>
              <Text color="#949595" id="futuro_valor_liquido" data-testid="futuro_valor_liquido">
                {Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(precos.parcelas[numeroDeParcelas - 1].valorTotal)}{" "}
                / {precos.assinatura.intervalo === "ANUAL" ? "Ano" : "Mês"}
              </Text>

              <Row style={{ marginTop: 20, marginBottom: "10px" }}>
                <Text color="#949595">
                  Data da próxima cobrança:{" "}
                  <span id="futuro_data_proxima_cobranca" data-testid="futuro_data_proxima_cobranca">
                    {assinaturaAtual.assinatura.trial && precos.assinatura.periodo.dataFimTrial
                      ? formataDataJSX(precos.assinatura.periodo.dataDaCobranca)
                      : formataDataJSX(precos.assinatura.dataDaProximaCobranca)}
                  </span>
                </Text>
              </Row>
            </Row>
          ) : (
            <>
              <Row type="flex" justify="space-between" style={{ marginBottom: "5px" }}>
                <Text emphasys>Valor a ser cobrado agora</Text>
                <Text color="#949595" id="futuro_valor_liquido_agora" data-testid="futuro_valor_liquido_agora">
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(precos.proRata.valor)}
                </Text>
              </Row>
              <Row style={{ marginBottom: "10px" }}>
                <Text color="#949595">
                  De{" "}
                  <span data-testid="futuro_prorata_periodo_inicio">
                    {formataDataJSX(precos.proRata.periodoDeAcesso.inicio)}
                  </span>{" "}
                  até{" "}
                  <span data-testid="futuro_prorata_periodo_fim">
                    {" "}
                    {formataDataJSX(precos.proRata.periodoDeAcesso.fim)}
                  </span>
                </Text>
              </Row>
              <Row type="flex" justify="space-between" style={{ marginBottom: "5px" }}>
                <Text emphasys>
                  {precos.assinatura.intervalo === "ANUAL" ? "Valor da próxima cobrança" : "Valor nos próximos meses"}
                </Text>
                <Text color="#949595" id="futuro_valor_liquido" data-testid="futuro_valor_liquido">
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(precos.valor)}{" "}
                  / {precos.assinatura.intervalo === "ANUAL" ? "Ano" : "Mês"}
                </Text>
              </Row>
              <Row style={{ marginBottom: "10px" }}>
                <Text color="#949595">
                  Data da próxima cobrança:
                  <span id="futuro_data_proxima_cobranca" data-testid="futuro_data_proxima_cobranca">
                    {" "}
                    {proximaDatacobranca()}
                  </span>
                </Text>
              </Row>
            </>
          )}
        </Col>
      </Card>
    </CustomCol>
  );
};
