import { Button, Card, LoadingContext, Radio, Text } from '@prev/ui-kit'
import { Col, Row } from 'antd'
import { useContext } from 'react'
import styled from 'styled-components'
import { CalendarOutlined } from '@ant-design/icons'
import useWindowSize from '../../hooks/useWindowSize'
import { PlanoContext } from '../../context/plano/PlanoContext'
import Swal from 'sweetalert2'

const CustomCard = styled(Card)`
  box-shadow: ${props =>
    props.checked
      ? '0px 0px 3px rgb(36, 199, 53);'
      : '0px 0px 3px rgba(0, 0, 0, 0.3);'};
  cursor: pointer;
`

const Plano = () => {
  const {
    setNovaAssinatura,
    novaAssinatura,
    setStep,
    assinaturaAtual,
    precos
  } = useContext(PlanoContext)
  const { loading } = useContext(LoadingContext)
  const { width } = useWindowSize()

  if (loading) return null

  return (
    <Col>
      <Text emphasys>Escolha seu plano</Text>
      <Row type="flex" justify="space-between">
        <Col xs={24} sm={24} md={24} lg={12} xl={12} span={12}>
          <CustomCard
            checked={novaAssinatura.intervalo === 'MENSAL'}
            id="card_escolha_mensal"
            data-testid="card_escolha_mensal"
            aria-label="Plano mensal"
            onClick={() => {
              if (novaAssinatura.intervalo !== 'MENSAL')
                setNovaAssinatura({ ...novaAssinatura, intervalo: 'MENSAL' })
            }}
          >
            <Row type="flex" justify="space-between" align="middle">
              <Col>
                <Row
                  align="middle"
                  style={{ columnGap: '10px', rowGap: '10px' }}
                >
                  <CalendarOutlined style={{ fontSize: '20px' }} />
                  <Col>
                    <Text emphasys>Plano mensal</Text>
                    <Text color="#949595">Sem desconto</Text>
                  </Col>
                </Row>
              </Col>
              <Radio checked={novaAssinatura.intervalo === 'MENSAL'} />
            </Row>
          </CustomCard>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} span={12}>
          <CustomCard
            id="card_escolha_anual"
            data-testid="card_escolha_anual"
            aria-label="Plano anual"
            checked={novaAssinatura.intervalo === 'ANUAL'}
            onClick={() => {
              if (novaAssinatura.intervalo !== 'ANUAL') {
                return Swal.fire({
                  title: 'Tem certeza?',
                  html: 'Você confirma a alteração para o plano anual ?',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3575AB',
                  cancelButtonColor: '#d33',
                  confirmButtonText:
                    "<span data-testid='modal_confirmacao_sim'>Sim</span>",
                  cancelButtonText:
                    "<span data-testid='modal_confirmacao_nao'>Não</span>"
                }).then(result => {
                  if (result.value)
                    setNovaAssinatura({ ...novaAssinatura, intervalo: 'ANUAL' })
                })
              }
            }}
          >
            <Row type="flex" justify="space-between" align="middle">
              <Col>
                <Row
                  align="middle"
                  style={{ columnGap: '10px', rowGap: '10px' }}
                >
                  <CalendarOutlined style={{ fontSize: '20px' }} />
                  <Col>
                    <Text emphasys>Plano anual com desconto</Text>
                    <Text color="#949595">Economize 20% na anuidade</Text>
                  </Col>
                </Row>
              </Col>
              <Radio checked={novaAssinatura.intervalo === 'ANUAL'} />
            </Row>
          </CustomCard>
        </Col>
      </Row>
      <Button
        style={{ margin: '20px 0' }}
        disabled={!novaAssinatura.intervalo}
        onClick={() => setStep(1)}
        size="large"
        id="button_prosseguir"
        aria-label="Avançar"
        data-testid="button_prosseguir"
      >
        Avançar
      </Button>
      <Row
        type="flex"
        style={{
          flexDirection: 'column',
          rowGap: '20px',
          columnGap: '10px',
          marginBottom: '100px'
        }}
      >
        <>
          <Text emphasys>Posso pagar parcelado?</Text>
          <Text color="#949595">
            Sim. No plano anual você pode pagar parcelado em até 6 vezes em
            parcelas mínimas de R$500,00. O plano mensal não oferece
            parcelamento. Para pagar parcelado você precisa usar um cartão de
            crédito pois não há parcelamento no boleto ou pix.
          </Text>
        </>

        {novaAssinatura?.intervalo === 'MENSAL' ? (
          <>
            <Text emphasys>
              Quais as vantagens de trocar o meu plano mensal para o anual?
            </Text>
            <Text color="#949595">
              Migrando para o plano anual você economiza 20% no pagamento da
              assinatura e ainda pode parcelar em até 6x com juros no cartão.
              Você também garante o "congelamento" do preço pela duração do
              plano anual. Nenhum reajuste é aplicado durante o ciclo atual do
              plano atual. Independente disso, você também pode cancelar a
              qualquer momento para impedir que o plano seja renovado no futuro,
              se desejar.
            </Text>
          </>
        ) : (
          <>
            <Text emphasys>O que é a "Data da próxima cobrança"?</Text>
            <Text color="#949595">
              É a data em que seu plano será renovado e cobrado futuramente caso
              você não cancele sua assinatura. Porém você pode cancelar sua
              assinatura a qualquer momento para evitar renovações futuras em
              até 03 cliques dentro da sua própria plataforma. Veja como é fácil
              cancelar{' '}
              <a
                href="https://ajuda.previdenciarista.com/article/151-como-cancelar-minha-assinatura"
                target={'_blank'}
              >
                clicando aqui
              </a>
              .
            </Text>
          </>
        )}

        {novaAssinatura?.tiposDeModulo?.length < 2 && (
          <>
            <Text emphasys>Turbine sua assinatura com módulos extras!</Text>
            <Text color="#949595">
              Você pode complementar sua assinatura com outros serviços extras
              do Previdenciarista. Confira os módulos acima e turbine sua
              assinatura para adquirir mais clientes e facilitar a rotina do seu
              escritório.
            </Text>
          </>
        )}
        {precos && precos.proRata && (
          <>
            <Text emphasys>O que é o desconto pró-rata?</Text>
            <Text color="#949595">
              No pró-rata calculamos o período que você já utilizou de um plano
              ou módulo, caso opte por alterar sua assinatura durante um período
              vigente e já pago anteriormente. Dessa forma você recebe o período
              não utilizado na forma de desconto.
            </Text>
            <Text emphasys>
              Qual a diferença entre "Valor a ser cobrado agora" e "Valor da
              próxima cobrança"?
            </Text>
            <Text color="#949595">
              O "Valor a ser cobrado agora" é o valor que cobraremos nesse
              momento na sua assinatura, podendo ser o valor integral de uma
              assinatura caso você não esteja dentro de um período pago, ou
              valores pró-rata referente a mudanças na assinatura como
              acréscimos de módulos no período atual. O "Valor da próxima
              cobrança" se refere ao valor dos próximos períodos, quando sua
              assinatura se renovar automaticamente no futuro. Esse seria o
              "valor cheio" do seu plano, sem descontos pró-rata aplicados.
            </Text>
          </>
        )}
      </Row>
    </Col>
  )
}

export default Plano
