// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#containerRoot {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

@media only screen and (max-width: 400px) {
    #containerRoot {
        align-items: unset;
    }
}

.swal2-confirm {
    width: 200px;
}

.ant-tooltip-inner {
    background: transparent !important;
    color: #3575AB !important;
    font-weight: 700 !important;
    box-shadow: none !important;
    padding: 0 !important;
}

.ant-tooltip-arrow {
    display: none !important;
}

@media only screen and (max-width: 800px) {
    .modal-relogin-wrapper {
        top: -70px!important;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/globalstyles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,SAAS;IACT,sBAAsB;AAC1B;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kCAAkC;IAClC,yBAAyB;IACzB,2BAA2B;IAC3B,2BAA2B;IAC3B,qBAAqB;AACzB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI;QACI,oBAAoB;IACxB;AACJ","sourcesContent":["#containerRoot {\n    height: 100%;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    overflow-x: hidden;\n}\n\n* {\n    padding: 0;\n    margin: 0;\n    box-sizing: border-box;\n}\n\n@media only screen and (max-width: 400px) {\n    #containerRoot {\n        align-items: unset;\n    }\n}\n\n.swal2-confirm {\n    width: 200px;\n}\n\n.ant-tooltip-inner {\n    background: transparent !important;\n    color: #3575AB !important;\n    font-weight: 700 !important;\n    box-shadow: none !important;\n    padding: 0 !important;\n}\n\n.ant-tooltip-arrow {\n    display: none !important;\n}\n\n@media only screen and (max-width: 800px) {\n    .modal-relogin-wrapper {\n        top: -70px!important;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
