import React, { useContext } from 'react'
import Header from '../../../components/header/Header'
import { PageContainer } from '../../../styles'
import { Button, Text } from '@prev/ui-kit'
import { Col, Row } from 'antd'
import styled from 'styled-components'
import successIcon from '../../../assets/images/successIcon.svg'
import failedIcon from '../../../assets/images/failedIcon.svg'
import { PlanoContext } from '../../../context/plano/PlanoContext'

const StyledText = styled(Text)`
  color: #949595;
  font-weight: 600;
`

function AlterarPlanoError() {
  const { erroPagamento, setMeioDePagamento, setPagamento, setErroPagamento } =
    useContext(PlanoContext)

  const handleRetry = () => {
    setMeioDePagamento('CARTAO_DE_CREDITO')
    setPagamento(null)
    setErroPagamento(null)
  }

  return (
    <PageContainer>
      <Header captionOff />
      <Row align="center" gutter={[0, 16]}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          span={24}
          style={{ textAlign: 'center', alignItems: 'center' }}
        >
          <img
            className="corners"
            style={{
              marginBottom: '20px'
            }}
            src={
              erroPagamento && erroPagamento.icon === 'SUCCESS'
                ? successIcon
                : failedIcon
            }
            alt="Status icon"
            id="status-icon"
            data-testid="status-icon"
          />
          <Text
            id="erroPagamento-messsage"
            data-testid="erroPagamento-message"
            color="red"
            emphasys
            size="lg"
            style={{ marginBottom: '20px' }}
          >
            {erroPagamento.message
              ? erroPagamento.message
              : 'Não foi possível validar seu cartão'}
          </Text>
          {erroPagamento.hideCaption ? null : (
            <>
              <StyledText>
                * Certifique-se de estar usando um cartão de crédito
              </StyledText>
              <StyledText>
                * Que o nome, número, código de segurança (cvv) e data de
                validade estejam corretos
              </StyledText>
              <StyledText>
                * Que o cartão de crédito é o seu cartão físico e não virtual
              </StyledText>
              <StyledText style={{ marginBottom: '20px' }}>
                * Que o cartão informado não esteja vencido
              </StyledText>
              <Text style={{ marginBottom: '20px' }}>
                Você pode tentar outro cartão de crédito, ou pague com Pix
              </Text>
            </>
          )}

          {!erroPagamento.hideButtons ? (
            <Button onClick={() => handleRetry()}>Tentar novamente</Button>
          ) : (
            <Button
              id="btn-redirecionar-painel"
              data-testid="btn-redirecionar-painel"
              aria-label="Ir para o painel"
              onClick={() =>
                (window.location.href = '/configuracoes/financeiro')
              }
            >
              Ir para o painel
            </Button>
          )}
        </Col>
      </Row>
    </PageContainer>
  )
}

export default AlterarPlanoError
