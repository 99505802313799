import React from 'react'
import PropTypes from 'prop-types'
import SafePayment from '../resolverPendencias/pagamentoSeguro/PagamentoSeguro'
import { HeaderContainer } from './styles'
import useWindowSize from '../../hooks/useWindowSize'
import infoPrevImage from '../../assets/images/logo.svg'
import infoPrev2Image from '../../assets/images/logo2.svg'
import { Row } from 'antd'
import { Text } from '@prev/ui-kit'
import { ArrowLeftOutlined } from '@ant-design/icons'

function Header(props) {
  const { captionOff, back, setStep, step } = props
  const size = useWindowSize()
  return (
    <HeaderContainer captionOff={captionOff || size.width < 1023}>
      {back && (
        <Row
          style={{
            cursor: 'pointer',
            rowGap: '10px',
            columnGap: '10px',
            position: 'absolute',
            left: 0
          }}
          align="middle"
          onClick={() => (window.location.href = '/configuracoes/financeiro')}
        >
          <ArrowLeftOutlined style={{ color: '#F26526', fontSize: '15px' }} />
          <Text color="#F26526">Painel</Text>
        </Row>
      )}
      <div>
        <img
          src={!back ? infoPrevImage : infoPrev2Image}
          alt="Logo Previdenciarista"
          id="prev-logo"
          aria-label="Logo Previdenciarista"
          data-testid="prev-logo"
        />
      </div>
      {!captionOff && size.width > 1023 && !back && <SafePayment />}
    </HeaderContainer>
  )
}

Header.propTypes = {
  captionOff: PropTypes.bool
}

Header.defaultProps = {
  captionOff: false
}

export default Header
