import { Col, Spin } from "antd";
import React from "react";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

const CustomContainer = styled(Col)`
  position: absolute;
  background-color: #fff;
  z-index: 8888;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const antIcon = <LoadingOutlined style={{ fontSize: 50, color: "#F26526" }} spin />;

function Loading(props) {
  const { loading } = props;
  if (loading) {
    return (
      <CustomContainer>
        <div>
          <Spin size="large" indicator={antIcon} />
        </div>
      </CustomContainer>
    );
  }
  return null;
}

Loading.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default Loading;
