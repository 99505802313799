import { Button } from "@prev/ui-kit";
import { Col, Form } from "antd";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const CreditCardContainer = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  row-gap: 20px;
  column-gap: 20px;
  @media (max-width: 1023px) {
    order: 10;
  }
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
