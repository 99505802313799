import React, { useContext } from 'react'
import Header from '../../../components/header/Header'
import { PageContainer } from '../../../styles'
import { Button, Text } from '@prev/ui-kit'
import { Col, Row } from 'antd'
import successIcon from '../../../assets/images/successIcon.svg'
import { PlanoContext } from '../../../context/plano/PlanoContext'

function AlterarPlanoSucesso() {
  const { sucessoPagamento } = useContext(PlanoContext)

  const returnDomain = () => {
    window.location.href = '/configuracoes/financeiro'
    return
  }

  return (
    <PageContainer>
      <Header captionOff />
      <Row align="center" gutter={[0, 16]}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          span={24}
          style={{ textAlign: 'center', alignItems: 'center' }}
        >
          <img
            style={{ marginBottom: '30px' }}
            src={successIcon}
            alt="Sucesso"
            id="sucess-icon"
            data-testid="sucess-icon"
          />
          <Text
            data-testid="plano-alterado-sucesso"
            style={{ marginBottom: '30px' }}
            emphasys
            size="xl"
          >
            {sucessoPagamento.message ||
              'Plano alterado com sucesso. Volte para o seu painel para continuar acessando o Prev.'}
          </Text>
          <Text
            color="#949595"
            style={{ marginBottom: '10px', fontStyle: 'italic' }}
          >
            {sucessoPagamento.caption}
          </Text>
          <Button
            id="btn-redirecionar-painel"
            data-testid="btn-redirecionar-painel"
            aria-label="Ir para o painel"
            onClick={() => returnDomain()}
          >
            Ir para o painel
          </Button>
        </Col>
      </Row>
    </PageContainer>
  )
}

export default AlterarPlanoSucesso
