/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
import axios from "axios";
// import { API_HOST, API_HOST_PREV_DASHBOARD } from 'core/Constants';
import { getToken } from "./token";
import { API_HOST, API_HOST_DASHBOARD, API_HOST_V2 } from "../utils/constants";

export const withToken = (fn) => async (params) => {
  if (!getToken()) return;
  const token = getToken();
  return fn({ token, ...params });
};

const handleErrors = (error) => {
  // eslint-disable-next-line no-unused-vars
  const { response } = error || {};

  if (window._trackJs && window.trackJs) {
    if (response && response.config && response.config.data) {
      try {
        const obj = JSON.parse(response.config.data);
        if (obj.numero) {
          delete obj.numero;
        }
        if (obj.cvv) {
          delete obj.cvv;
        }

        window.trackJs.console.log(obj);
      } catch (e) {}
    }
  }

  throw error;
};

export const authenticatedPatch = withToken(async ({ path, data, token, host = API_HOST }) => {
  const url = `${host}${path}`;
  const auth = `Bearer ${token}`;

  return axios
    .patch(url, data, {
      headers: { Authorization: auth },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      if (response.status === 204) {
        return [];
      }
    })
    .catch(handleErrors);
});

export const authenticatedPut = withToken(async ({ path, data, token, host = API_HOST, headers = {} }) => {
  const url = `${host}${path}`;
  const auth = `Bearer ${token}`;

  return axios
    .put(url, data, {
      headers: {
        Authorization: auth,
        ...headers,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      if (response.status === 204) {
        return [];
      }
    })
    .catch(handleErrors);
});

export const authenticatedDelete = withToken(async ({ path, token, host = API_HOST }) => {
  const url = `${host}${path}`;
  const auth = `Bearer ${token}`;

  return axios
    .delete(url, {
      headers: { Authorization: auth },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      if (response.status === 204) {
        return {};
      }
    })
    .catch(handleErrors);
});

export const get = async ({ path, token, host = API_HOST_DASHBOARD }) => {
  const url = `${host}${path}`;
  const auth = `Bearer ${token}`;

  return axios
    .get(url, {
      headers: { Authorization: auth },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      if (response.status === 204) {
        return [];
      }
    })
    .catch(handleErrors);
};

// export const getV2WithHeader = async ({ path, token }) => {
//   const url = `${API_HOST_PREV_DASHBOARD}${path}`;
//   const auth = `Bearer ${token}`;

//   return axios
//     .get(url, {
//       headers: { Authorization: auth },
//     })
//     .then((response) => response)
//     .catch(handleErrors);
// };

// export const getV2 = async ({ path, token }) => {
//   const url = `${API_HOST_PREV_DASHBOARD}${path}`;
//   const auth = `Bearer ${token}`;

//   return axios
//     .get(url, {
//       headers: { Authorization: auth },
//     })
//     .then((response) => {
//       if (response.data) {
//         return response.data;
//       }
//       if (response.status === 204) {
//         return [];
//       }
//     })
//     .catch(handleErrors);
// };

export const authenticatedGetWithHeader = withToken(async ({ path, token, host = API_HOST, headers = {} }) => {
  const url = `${host}${path}`;
  const auth = `Bearer ${token}`;

  return axios({
    url,
    method: "GET",
    headers: { Authorization: auth, ...headers },
  })
    .then((response) => response)
    .catch(handleErrors);
});

export const authenticatedGet = withToken(async ({ path, token, host = API_HOST, headers = {}, ...rest }) => {
  const url = `${host}${path}`;
  const auth = `Bearer ${token}`;

  return axios({
    url,
    method: "GET",
    headers: { Authorization: auth, ...headers },
    ...rest,
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      if (response.status === 204) {
        if (path !== "/pagamentos") {
          return [];
        }
        return null;
      }
    })
    .catch((e) => {
      if (path !== "/pagamentos") {
        return handleErrors(e);
      }
      return null;
    });
});

export const authenticatedPost = withToken(
  async ({ path, data, token, headers = {}, host = API_HOST, params = {} }) => {
    const url = `${host}${path}`;
    const auth = `Bearer ${token}`;
    return axios
      .post(url, data, {
        headers: { Authorization: auth, ...headers },
        params,
      })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
        if (response.status === 204) {
          return [];
        }
      })
      .catch(handleErrors);
  }
);

export const normalGet = async ({ path, data, host = API_HOST }) => {
  const url = `${host}${path}`;
  return axios
    .get(url, data)
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      if (response.status === 204) {
        return [];
      }
    })
    .catch(handleErrors);
};

export const normalPost = async ({ path, data, host = API_HOST_V2, headers = null }) => {
  const url = `${host}${path}`;
  return axios
    .post(url, data, {headers})
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      if (response.status === 204) {
        return [];
      }
    })
    .catch(handleErrors);
};

export const normalPut = async ({ path, data, host = API_HOST }) => {
  const url = `${host}${path}`;
  return axios
    .put(url, data)
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      if (response.status === 204) {
        return [];
      }
    })
    .catch(handleErrors);
};

export const authenticatedGetFile = withToken(async ({ path, token, host = API_HOST }) => {
  const url = `${host}${path}`;
  const auth = `Bearer ${token}`;

  return axios({
    url,
    method: "GET",
    responseType: "blob",
    headers: { Authorization: auth },
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      if (response.status === 204) {
        return [];
      }
    })
    .catch(handleErrors);
});

export const authenticatedPostFile = withToken(async ({ path, data, config = {}, token, host = API_HOST }) => {
  const url = `${host}${path}`;
  const auth = `Bearer ${token}`;

  return axios
    .post(url, data, {
      headers: { Authorization: auth },
      ...config,
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      if (response.status === 204) {
        return [];
      }
    })
    .catch(handleErrors);
});

export const authenticatedPostFileUploadProgress = withToken(
  async ({ path, data, config = {}, token, host = API_HOST }) => {
    const url = `${host}${path}`;
    const auth = `Bearer ${token}`;

    return axios
      .post(url, data, {
        headers: { Authorization: auth },
        ...config,
      })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
        if (response.status === 204) {
          return [];
        }
      })
      .catch(handleErrors);
  }
);
