import { Col, Form, Row } from 'antd'
import React, { useContext, useState } from 'react'
import {
  CreditCard,
  getBrandByCardNumber,
  Select,
  Text,
  getPatternByCardNumber,
  creditCardNamePattern
} from '@prev/ui-kit'
import { CreditCardFilled } from '@ant-design/icons'
import { CreditCardContainer, FormContainer, StyledButton } from './styles'
import useWindowSize from '../../../hooks/useWindowSize'
import SafePayment from '../pagamentoSeguro/PagamentoSeguro'
import imgCard from '../../../assets/images/cardbrand.svg'
import { ResolvePendenciesContext } from '../../../context/resolverPendencias/ResolvePendenciesContext'
import { SelectParcelas } from '../../pagamento/SelectParcelas'

const brandOptions = [
  { value: 'VISA', label: 'Visa' },
  { value: 'MASTERCARD', label: 'Mastercard' },
  { value: 'ELO', label: 'Elo' },
  { value: 'AMERICAN_EXPRESS', label: 'American Express' },
  { value: 'DINERS', label: 'Diners' },
  { value: 'HIPERCARD', label: 'Hipercard' }
]

function CreditCardForm({ form, setFlipped, setForm }) {
  const { width } = useWindowSize()
  const [showBrandSelect, setShowBrandSelect] = useState(false)
  const { postPagarFatura } = useContext(ResolvePendenciesContext)

  return (
    <CreditCardContainer xs={24} sm={24} md={24} lg={7} xl={7} span={7}>
      {width > 1023 && (
        <Text emphasys>Pagamento da fatura no cartão de crédito</Text>
      )}
      <FormContainer onFinish={data => postPagarFatura(data)}>
        <Form.Item
          name="nome"
          style={{ width: '100%' }}
          onChange={e =>
            setForm({
              ...form,
              nome: e.target.value
            })
          }
          rules={[
            {
              required: true,
              message: 'Digite o nome impresso no cartão'
            },
            {
              pattern: creditCardNamePattern,
              message: 'Digite um nome válido',
              whitespace: true
            }
          ]}
        >
          <CreditCard.Name
            id="inpt-nome-cartao"
            aria-label="Nome impresso no cartão"
            label="Nome impresso no cartão"
            placeholder="Digite o nome"
            data-testid="inpt-nome-cartao"
          />
        </Form.Item>
        <Form.Item
          style={{ width: '100%' }}
          name="numero"
          rules={[
            {
              required: true,
              message: 'Digite o número impresso no cartão'
            },
            {
              pattern: getPatternByCardNumber(form?.numero),
              message: 'Digite um número de cartão válido',
              whitespace: true
            }
          ]}
          onChange={e =>
            setForm({
              ...form,
              numero: e.target.value
            })
          }
        >
          <CreditCard.Number
            id="inpt-numero-cartao"
            label="Número do cartão"
            placeholder="Digite o número do cartão"
            aria-label="Número do cartão"
            maskChar=""
            onBlur={event => {
              if (event.target.value.length === 19) {
                const brand = getBrandByCardNumber(event.target.value)
                return !brand.brand
                  ? setShowBrandSelect(true)
                  : setShowBrandSelect(false)
              }
              return setShowBrandSelect(false)
            }}
            data-testid="inpt-numero-cartao"
          />
        </Form.Item>

        {showBrandSelect && (
          <Form.Item
            style={{ width: '100%' }}
            name="bandeira"
            rules={[
              {
                required: true,
                message: 'Selecione a bandeira do cartão'
              }
            ]}
          >
            <Select
              id="inpt-bandeira-cartao"
              label="Bandeira do cartão"
              placeholder="Selecione a bandeira do cartão"
              options={brandOptions}
              aria-label="Bandeira do cartão"
              data-testid="inpt-bandeira-cartao"
            />
          </Form.Item>
        )}
        <Row type="flex" justify="space-between" style={{ width: '100%' }}>
          <Col span={11}>
            <Form.Item
              name="dataDeVencimento"
              rules={[
                {
                  required: true,
                  message: 'Digite a data de vencimento'
                },
                {
                  pattern: /^(0[1-9]|1[012]) [/] 20\d{2}/,
                  message:
                    'Data inválida, informe mês e o ano completo (MM/AAAA)',
                  whitespace: true
                },
                {
                  validator: (rule, value) => {
                    if (value) {
                      const [month, year] = value.split('/')
                      const currentMonth = new Date().getMonth() + 1
                      const currentYear = new Date().getFullYear()
                      if (Number(year) < currentYear) {
                        return Promise.reject(
                          'Cartão vencido. Informe um cartão com data superior a atual.'
                        )
                      }
                      if (
                        Number(year) === currentYear &&
                        Number(month) <= currentMonth
                      ) {
                        return Promise.reject(
                          'Cartão vencido. Informe um cartão com data superior a atual.'
                        )
                      }
                      return Promise.resolve()
                    }
                  }
                }
              ]}
              onChange={e =>
                setForm({
                  ...form,
                  dataDeVencimento: e.target.value
                })
              }
            >
              <CreditCard.ExpiryDate
                id="inpt-data-de-vencimento"
                label="Vencimento"
                placeholder="Digite a data de vencimento"
                aria-label="Data de vencimento"
                data-testid="inpt-data-de-vencimento"
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              name="cvv"
              rules={[
                {
                  required: true,
                  message: 'Digite o código CVV'
                },
                {
                  pattern: /\d{3}|\d{5}/,
                  message: 'Código inválido, digite 3 ou 4 dígitos',
                  whitespace: true
                }
              ]}
              onChange={e =>
                setForm({
                  ...form,
                  cvv: e.target.value
                })
              }
            >
              <CreditCard.Cvv
                id="inpt-cvv"
                label="Código CVV"
                placeholder="CVV"
                onFocus={() => setFlipped(true)}
                onBlur={() => setFlipped(false)}
                maskChar=""
                aria-label="Código CVV"
                data-testid="inpt-cvv"
              />
            </Form.Item>
          </Col>
        </Row>
        <StyledButton
          size="large"
          aria-label="Pagar com cartão de crédito"
          icon={<CreditCardFilled />}
          color="secondary"
          htmlType="submit"
          style={{ margin: '1.5rem 0' }}
          id="btn-pagar-com-cartao"
          data-testid="btn-pagar-com-cartao"
        >
          Pagar com cartão de crédito
        </StyledButton>
        {width < 1024 && <SafePayment />}
        <img
          src={imgCard}
          alt="Bandeiras de cartão de crédito"
          style={{ width: '75%', marginTop: '10px' }}
        />
      </FormContainer>
    </CreditCardContainer>
  )
}

export default CreditCardForm
