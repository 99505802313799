import { Col, Form, Row } from 'antd'
import React, { useContext, useState } from 'react'
import {
  Button,
  CreditCard,
  FlipCard,
  getBrandByCardNumber,
  Select,
  Text,
  getPatternByCardNumber,
  creditCardNamePattern
} from '@prev/ui-kit'
import Swal from 'sweetalert2'
import moment from 'moment'
import { CreditCardContainer, FormContainer } from './styles'
import useWindowSize from '../../hooks/useWindowSize'
import { validateCard } from '../../utils/validateCard'
import { confirmModalText } from '../../utils/confirmModalText'
import imgCard from '../../assets/images/cardbrand.svg'
import { PlanoContext } from '../../context/plano/PlanoContext'

const brandOptions = [
  { value: 'VISA', label: 'Visa' },
  { value: 'MASTERCARD', label: 'Mastercard' },
  { value: 'ELO', label: 'Elo' },
  { value: 'AMERICAN_EXPRESS', label: 'American Express' },
  { value: 'DINERS', label: 'Diners' },
  { value: 'HIPERCARD', label: 'Hipercard' }
]

function AlterarCartaoDeCredito({
  form,
  setForm,
  setEditCreditCard,
  novaAssinatura,
  precos,
  assinaturaAtual
}) {
  const { width } = useWindowSize()
  const [showBrandSelect, setShowBrandSelect] = useState(false)
  const { postReativarPlano, AnalyticsService } = useContext(PlanoContext)
  const [isFlipped, setIsFlipped] = useState(false)

  const regraMostrarModalConfirmaPagto =
    novaAssinatura.intervalo === 'ANUAL' &&
    precos &&
    (precos.proRata ||
      moment(precos.assinatura.periodo.dataDaCobranca).isSameOrBefore(
        new Date()
      ))

  const handleSubmit = async data => {
    try {
      const form = validateCard(data)
      if (form.hasErrors) return
      //   fetchInfo();
      await postReativarPlano(form.payload)
      setEditCreditCard(false)
    } catch (err) {
      Swal.fire({
        text: err.response.data.error_description || 'Erro ao alterar cartão',
        icon: 'error',
        buttonsStyling: true,
        confirmButtonText: 'Tentar novamente',
        confirmButtonColor: '#3575AB'
      })
    }
  }

  return (
    <CreditCardContainer>
      <FormContainer
        onFinish={data => {
          if (regraMostrarModalConfirmaPagto) {
            return Swal.fire({
              title: 'Tem certeza?',
              html: confirmModalText(
                precos,
                'CARTAO_DE_CREDITO',
                assinaturaAtual,
                form?.numeroDeParcelas
              ),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3575AB',
              cancelButtonColor: '#d33',
              confirmButtonText:
                "<span data-testid='modal_confirmacao_sim'>Sim</span>",
              cancelButtonText:
                "<span data-testid='modal_confirmacao_nao'>Não</span>"
            }).then(result => {
              if (result.value) {
                if (novaAssinatura.intervalo === 'ANUAL')
                  AnalyticsService.trackEvent(
                    'Confirmou alteração para o plano anual no cartão'
                  )
                return handleSubmit(data)
              }
              AnalyticsService.trackEvent(
                `Negou alteração para o plano anual no cartão`
              )
            })
          }
          handleSubmit(data)
        }}
        style={{ textAlign: 'left' }}
      >
        {width > 1023 && (
          <Text
            emphasys
            size="lg"
            style={{ width: '100%', marginBottom: '20px' }}
          >
            Atualizar cartão de crédito
          </Text>
        )}
        <Form.Item
          name="nome"
          style={{ width: '100%' }}
          onChange={e =>
            setForm({
              ...form,
              nome: e.target.value
            })
          }
          rules={[
            {
              required: true,
              message: 'Digite o nome impresso no cartão'
            },
            {
              pattern: creditCardNamePattern,
              message: 'Digite um nome válido',
              whitespace: true
            }
          ]}
        >
          <CreditCard.Name
            id="inpt-nome-cartao"
            aria-label="Nome impresso no cartão"
            label="Nome impresso no cartão"
            placeholder="Digite o nome"
            data-testid="inpt-nome-cartao"
          />
        </Form.Item>
        <Form.Item
          style={{ width: '100%' }}
          name="numero"
          rules={[
            {
              required: true,
              message: 'Digite o número impresso no cartão'
            },
            {
              pattern: getPatternByCardNumber(form?.numero),
              message: 'Digite um número de cartão válido',
              whitespace: true
            }
          ]}
          onChange={e =>
            setForm({
              ...form,
              numero: e.target.value
            })
          }
        >
          <CreditCard.Number
            id="inpt-numero-cartao"
            label="Número do cartão"
            placeholder="Digite o número do cartão"
            aria-label="Número do cartão"
            maskChar=""
            onBlur={event => {
              if (event.target.value.length === 19) {
                const brand = getBrandByCardNumber(event.target.value)
                return !brand.brand
                  ? setShowBrandSelect(true)
                  : setShowBrandSelect(false)
              }
              return setShowBrandSelect(false)
            }}
            data-testid="inpt-numero-cartao"
          />
        </Form.Item>

        {showBrandSelect && (
          <Form.Item
            style={{ width: '100%' }}
            name="bandeira"
            rules={[
              {
                required: true,
                message: 'Selecione a bandeira do cartão'
              }
            ]}
          >
            <Select
              id="inpt-bandeira-cartao"
              label="Bandeira do cartão"
              placeholder="Selecione a bandeira do cartão"
              options={brandOptions}
              aria-label="Bandeira do cartão"
              data-testid="inpt-bandeira-cartao"
            />
          </Form.Item>
        )}
        <Row type="flex" justify="space-between" style={{ width: '100%' }}>
          <Col span={11}>
            <Form.Item
              name="dataDeVencimento"
              rules={[
                {
                  required: true,
                  message: 'Digite a data de vencimento'
                },
                {
                  pattern: /^(0[1-9]|1[012]) [/] 20\d{2}/,
                  message:
                    'Data inválida, informe mês e o ano completo (MM/AAAA)',
                  whitespace: true
                },
                {
                  validator: (rule, value) => {
                    if (value) {
                      const [month, year] = value.split('/')
                      const currentMonth = new Date().getMonth() + 1
                      const currentYear = new Date().getFullYear()
                      if (Number(year) < currentYear) {
                        return Promise.reject(
                          'Cartão vencido. Informe um cartão com data superior a atual.'
                        )
                      }
                      if (
                        Number(year) === currentYear &&
                        Number(month) <= currentMonth
                      ) {
                        return Promise.reject(
                          'Cartão vencido. Informe um cartão com data superior a atual.'
                        )
                      }
                      return Promise.resolve()
                    }
                  }
                }
              ]}
              onChange={e =>
                setForm({
                  ...form,
                  dataDeVencimento: e.target.value
                })
              }
            >
              <CreditCard.ExpiryDate
                id="inpt-data-de-vencimento"
                label="Vencimento"
                placeholder="Digite a data de vencimento"
                aria-label="Data de vencimento"
                data-testid="inpt-data-de-vencimento"
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              name="cvv"
              rules={[
                {
                  required: true,
                  message: 'Digite o código CVV'
                },
                {
                  pattern: /\d{3}|\d{5}/,
                  message: 'Código inválido, digite 3 ou 4 dígitos',
                  whitespace: true
                }
              ]}
              onChange={e =>
                setForm({
                  ...form,
                  cvv: e.target.value
                })
              }
            >
              <CreditCard.Cvv
                id="inpt-cvv"
                label="Código CVV"
                placeholder="CVV"
                maskChar=""
                aria-label="Código CVV"
                data-testid="inpt-cvv"
                onFocus={() => setIsFlipped(true)}
                onBlur={() => setIsFlipped(false)}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* {precos?.parcelas && regraMostrarModalConfirmaPagto && (
          <Row style={{ width: '100%' }}>
            <Col span={24}>
              <Form.Item
                id="parcelamento-options"
                placeholder="Selecione o número de parcelas"
                aria-label="Opções de parcelamento"
                data-testid="select-numero-parcelas"
                initialValue={1}
                style={{ width: '100%' }}
                name="numeroDeParcelas"
                rules={[
                  {
                    required: true,
                    message: 'Selecione o parcelamento'
                  }
                ]}
              >
                <Select
                  id="parcelamento-options"
                  style={{
                    width: `100%`
                  }}
                  placement="bottomLeft"
                  onChange={value =>
                    setForm({
                      ...form,
                      numeroDeParcelas: value
                    })
                  }
                  getPopupContainer={() => document.body}
                  options={precos?.parcelas?.map(
                    ({
                      parcelas,
                      jurosPercentual,
                      valorTotal,
                      jurosValor,
                      valorDaParcela
                    }) => ({
                      value: parcelas,
                      label: `${Number(valorTotal).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL'
                      })} em ${parcelas}x de ${Number(
                        valorDaParcela
                      ).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL'
                      })} ${
                        parcelas === 1
                          ? ` (sem juros)`
                          : ` (juros de ${jurosPercentual}%)`
                      }`
                    })
                  )}
                  label="Parcelamento"
                  aria-label="Opções de parcelamento"
                />
              </Form.Item>
            </Col>
          </Row>
        )} */}
        <Row
          type="flex"
          style={{
            width: '100%',
            rowGap: '20px',
            columnGap: '20px',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            size="large"
            aria-label="Pagar com cartão de crédito"
            color="secondary"
            id="btn-editar-cartao-voltar"
            data-testid="btn-editar-cartao-voltar"
            onClick={() => setEditCreditCard(false)}
          >
            Voltar
          </Button>
          <Button
            size="large"
            aria-label="Pagar com cartão de crédito"
            color="primary"
            htmlType="submit"
            id="btn-pagar-com-cartao"
            data-testid="btn-pagar-com-cartao"
          >
            Atualizar cartão de crédito e pagar
          </Button>
        </Row>
      </FormContainer>
      {width > 1023 && (
        <Row
          type="flex"
          style={{ flexDirection: 'column', rowGap: '20px', columnGap: '20px' }}
          justify="space-between"
          align="middle"
        >
          <FlipCard
            number={form ? form.numero || '' : ''}
            cvv={form ? form.cvv || '' : ''}
            name={form ? form.nome || '' : ''}
            expiry={form ? form.dataDeVencimento || '' : ''}
            isFlipped={isFlipped}
            style={{ marginBottom: '100px', width: '100%' }}
          />
          <img
            src={imgCard}
            alt="Bandeiras de cartão de crédito"
            style={{ width: '60%', marginTop: '10px' }}
          />
        </Row>
      )}
    </CreditCardContainer>
  )
}

export default AlterarCartaoDeCredito
