import { createContext } from 'react'
import usePlano from './usePlano'

export const PlanoContext = createContext({})

const PlanoContextProvider = ({ children }) => {
  const plano = usePlano()

  return (
    <PlanoContext.Provider value={{ ...plano }}>
      {children}
    </PlanoContext.Provider>
  )
}

export default PlanoContextProvider
