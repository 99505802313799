import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "antd";
import { FieldTimeOutlined } from "@ant-design/icons";
import { Button, Text, Card } from "@prev/ui-kit";
import useWindowSize from "../../hooks/useWindowSize";
import qrcode from "../../assets/images/qrcode.svg";
import { getStatus } from "../../services/faturas";
import successGif from "../../assets/images/successGif.json";
import Lottie from "react-lottie";
import { PlanoContext } from "../../context/plano/PlanoContext";

const lottieOptions = {
  renderer: "svg",
  loop: false,
  autoplay: true,
  animationData: successGif,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid meet",
  },
};

function PixReativar() {
  const { postReativarPlano, setSucessoPix, setErroPagamento, pagamento } = useContext(PlanoContext);

  const { width } = useWindowSize();
  const [copied, setCopied] = React.useState(false);
  const [remainingTime, setRemainingTime] = React.useState();
  const [transition, setTransition] = useState(false);

  const returnDomain = () => {
    window.location.href = "/";
    return;
  };

  const clipboardCopy = (text) => {
    const base64Corverted = window.atob(text);
    navigator.clipboard.writeText(base64Corverted);
    setCopied(true);
  };

  const handleTimer = (dataDeCriacao) => {
    const createdAt = new Date(dataDeCriacao);
    const now = new Date();
    const diff = now - createdAt;
    const passedTime = Math.floor(diff / 1000);
    const updatedTimer = 900 - passedTime;
    setRemainingTime(updatedTimer);
  };

  useEffect(() => {
    if (remainingTime === 0 || !pagamento) return;

    const timeoutId = setTimeout(() => {
      handleTimer(pagamento.dataDeCriacao);
    }, 1000);
    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, [remainingTime]);

  async function getPix() {
    if (pagamento && pagamento.pix) handleTimer(pagamento.dataDeCriacao);
  }

  useEffect(() => {
    getPix();
  }, [pagamento]);

  const handleTransition = async () => {
    setSucessoPix(true);
    setTransition(true);
  };

  const checkPaid = async (timer, pix) => {
    try {
      const response = await getStatus(pix.uuid);
      if (response.status === "SUCESSO") {
        clearInterval(timer);
        handleTransition();
      }
      if (response.status === "CANCELADO") {
        clearInterval(timer);
        setErroPagamento({
          message: "Pagamento cancelado",
          type: "FATURA",
          status: 500,
          hideCaption: true,
        });
      }
    } catch (e) {
      return setErroPagamento({
        message:
          (e && e.response && e.response.data && e.response.data.error_description) ||
          "Ocorreu um erro ao verificar pagamento do PIX",
        status: e.response.status,
        type: "FATURA",
        hideCaption: true,
        hideButtons: e.response.status === 401,
      });
    }
  };

  useEffect(() => {
    if (!pagamento) return;
    const timer = setInterval(() => checkPaid(timer, pagamento), 5000);
    return () => clearInterval(timer);
  }, [pagamento]);

  if (!pagamento || !pagamento.pix) return null;

  return (
    <Col>
      <Card style={{ width: "100%", margin: 0 }}>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Row gutter={[0, 16]} type="flex" style={{ flexDirection: "column" }}>
              {width > 1023 && <Text emphasys>Pagamento da fatura no pix</Text>}
              <Col style={{ paddingLeft: width > 1023 ? "20px" : 0 }}>
                <Row
                  type="flex"
                  align={transition ? "middle" : "top"}
                  gutter={[16, 16]}
                  style={{
                    backgroundColor: transition ? "#00A88138" : "#FEF0E9",
                    padding: "8px",
                    rowGap: "10px",
                    columnGap: "10px",
                  }}
                >
                  <Col span={2}>
                    <FieldTimeOutlined
                      style={{
                        fontSize: "30px",
                        color: transition ? "#00A881" : "#F5A623",
                      }}
                    />
                  </Col>
                  <Col span={21}>
                    {remainingTime < 1 && !transition && (
                      <Row
                        type="flex"
                        style={{
                          rowGap: "10px",
                          columnGap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Text emphasys size="base">
                          Código PIX expirado
                        </Text>
                        <Button
                          onClick={() => postReativarPlano()}
                          size="small"
                          aria-label="Gerar novo código PIX"
                          id="btn-gerar-pix"
                          data-testid="btn-gerar-pix"
                        >
                          Gerar novo código PIX
                        </Button>
                      </Row>
                    )}
                    {remainingTime > 0 && !transition && (
                      <Text
                        emphasys
                        size="base"
                        id="pix-timer"
                        data-testid="pix-timer"
                        aria-label="Tempo restante para pagar a fatura no PIX"
                      >
                        Este QR code expira em {Math.floor(remainingTime / 60)}{" "}
                        {remainingTime > 60 ? "minutos" : "minuto"} e {remainingTime % 60}{" "}
                        {remainingTime % 60 > 1 ? "segundos" : "segundo"}
                      </Text>
                    )}
                    {!transition && (
                      <Text>Assim que realizar o pagamento você receberá a confirmação no seu email.</Text>
                    )}
                    {transition && (
                      <Text id="success-message" data-testid="success-message" emphasys>
                        Pix pago com sucesso!
                      </Text>
                    )}
                  </Col>
                </Row>
                <Row gutter={[0, 26]} type="flex" justify={transition ? "center" : "space-between"} align="middle">
                  {transition ? (
                    <Lottie width={250} isClickToPauseDisabled={true} options={lottieOptions} />
                  ) : (
                    <>
                      <Col
                        span={width > 1023 ? 8 : 24}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img src={qrcode} alt="QRcode" style={{ position: "absolute" }} />
                        <img
                          style={{
                            padding: "10px 0 10px 0",
                            filter: `${remainingTime > 0 ? "none" : "blur(5px)"}`,
                          }}
                          src={`data:image/jpeg;base64,${pagamento.pix.qrCodeImage}`}
                          alt="qr-code"
                          id="qr-code"
                          data-testid="qr-code"
                        />
                      </Col>
                      <Col span={width > 1023 ? 14 : 24}>
                        <Row type="flex" align="middle" style={{ height: "100%" }} gutter={[0, 16]}>
                          <Col span={24}>
                            <Text>
                              Escaneie o{" "}
                              <Text type="span" emphasys>
                                {" "}
                                QR code
                              </Text>{" "}
                              ou copie o{" "}
                              <Text type="span" emphasys>
                                Código PIX
                              </Text>
                              .
                            </Text>
                            <Text style={{ marginBottom: "20px" }}>
                              Abra o app da instituição que você possui o{" "}
                              <Text type="span" emphasys>
                                PIX
                              </Text>{" "}
                              cadastrado e realize o pagamento.
                            </Text>
                            <Button
                              onClick={() => clipboardCopy(pagamento.pix.copiaCola)}
                              style={{
                                width: "100%",
                                marginBottom: "30px",
                              }}
                              disabled={remainingTime < 1}
                              aria-label="Copiar código PIX"
                              id="btn-copiar-pix"
                              data-testid="btn-copiar-pix"
                            >
                              {copied ? "Copiado !" : "Copiar código PIX"}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )}
                </Row>
                {!transition ? (
                  <Row
                    type="flex"
                    style={{
                      flexDirection: "column",
                      marginBottom: "30px",
                    }}
                    gutter={[0, 12]}
                  >
                    <Text emphasys size="lg">
                      Como pagar com seu PIX ?
                    </Text>
                    <Text>* Utilize o aplicativo do seu banco copiando o código PIX ou escaneando o QR-Code.</Text>
                    <Text>* Confirme os dados de pagamento e o valor do seu pedido.</Text>
                    <Text>* Seu pagamento será processado e debitado do valor disponível em sua conta-corrente.</Text>
                  </Row>
                ) : (
                  <Row
                    type="flex"
                    style={{
                      flexDirection: "column",
                      marginBottom: "30px",
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    gutter={[0, 12]}
                  >
                    <Text>Pix pago com sucesso, seu acesso já deve estar liberado.</Text>
                    <Text>Acesse sua plataforma.</Text>
                    <Button
                      onClick={() => returnDomain()}
                      size="large"
                      aria-label="Acessar plataforma"
                      id="btn-acessar-plataforma"
                      data-testid="btn-acessar-plataforma"
                    >
                      Ir para o painel
                    </Button>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Col>
  );
}

export default PixReativar;
