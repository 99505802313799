import {
    normalPost
} from "./adapter";

import { API_HOST, REACT_APP_PREV_AUTH_TOKEN } from "../utils/constants";

export const logarSemEscritorio = async (payload) => {
    const body = new URLSearchParams({
        grant_type: `password`,
        username: payload.username,
        password: payload.password
    });

    const response = await normalPost({
        path: "/oauth/token",
        data: body,
        host: API_HOST,
        headers: {
            Authorization: `Basic ${REACT_APP_PREV_AUTH_TOKEN}`,
            "Content-Type": "application/x-www-form-urlencoded"
        }
    });

    return response;
}

export const recuperarSenhaSemEscritorio = async (payload) => {
    const body = {
        slug: null,
        username: payload.username
    }

    const response = await normalPost({
        path: "/oauth/senha/recupera",
        data: body,
        host: API_HOST
    });

    return response;
}