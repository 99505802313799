/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { BASE_MODULES } from '../context/plano/usePlano'
import {
  adicionaModuloLaudos,
  adicionaModuloIlimitado,
  encontraQuantidadeMaxima,
  removeModuloLaudos
} from '../utils/laudos'

export const useLaudos = (
  assinaturaAtual,
  novaAssinatura,
  setNovaAssinatura,
  precos,
  AnalyticsService,
  tipoMudanca
) => {
  const [moduloDeLaudos, setModuloDeLaudos] = useState(null)
  const [laudosMinMax, setLaudosMinMax] = useState({ min: -10, max: 0 })
  const [searchParams, _setSearchParams] = useSearchParams()

  const setupLaudosIniciais = () => {
    const { precos: precoAssinaturaAtual } = assinaturaAtual
    const { tiposDeModulo: modulosUsuario } = assinaturaAtual.assinatura
    const { tabelaDePrecoModulos } = precoAssinaturaAtual
    const { precosModulos } = tabelaDePrecoModulos
    const modulosContratados = [
      ...new Set([...modulosUsuario, ...BASE_MODULES])
    ]
    let moduloLaudosExistente = modulosContratados.find(e =>
      e.includes('LAUDOS')
    )
    moduloLaudosExistente = setQuantidadeLaudosIniciais(
      moduloLaudosExistente,
      precosModulos
    )
    setupLaudosMinMax(precosModulos)
    setupNovoModuloLaudo(modulosUsuario, precosModulos)
  }

  const setupLaudosMinMax = precosModulos => {
    const max = encontraQuantidadeMaxima(precosModulos)
    setLaudosMinMax({ min: -10, max: max + 20 })
  }

  const setQuantidadeLaudosIniciais = (laudoTipo, precosModulos) => {
    if (!precosModulos) return
    const moduloDeLaudos = precosModulos?.find(e => e.tipo === laudoTipo)
    return setModuloDeLaudos(moduloDeLaudos?.tipo)
  }

  const handlerSelectLaudos = tipo => {
    setModuloDeLaudos(tipo)
    let assinaturaAtualizada = novaAssinatura
    const { tabelaDePrecoModulos } = precos
    const moduloLaudosSelecionado = tipo
    assinaturaAtualizada = adicionaModuloLaudos(
      tabelaDePrecoModulos,
      moduloLaudosSelecionado,
      novaAssinatura
    )
    const existeLaudo = novaAssinatura.tiposDeModulo.some(modulo =>
      modulo.includes('LAUDOS')
    )
    const existeLaudosAtualizada = assinaturaAtualizada.tiposDeModulo.some(
      modulo => modulo.includes('LAUDOS')
    )
    if (existeLaudo && !existeLaudosAtualizada)
      AnalyticsService.trackEvent(
        `Removeu um módulo em ${tipoMudanca?.toLowerCase()} assinatura`
      )
    if (!existeLaudo && existeLaudosAtualizada)
      AnalyticsService.trackEvent(
        `Adicionou um módulo em ${tipoMudanca?.toLowerCase()} assinatura`
      )
    setNovaAssinatura(assinaturaAtualizada)
  }

  const setupNovoModuloLaudo = (tiposDeModulo, precosModulos) => {
    const moduloQuery = searchParams?.get('modulo')?.toUpperCase()
    if (moduloQuery !== 'LAUDOS') return
    const modulosAtual = tiposDeModulo
    const modulosLaudosDisponiveis = precosModulos.filter(e =>
      e.tipo.includes('LAUDOS')
    )
    const moduloLaudosAtual = modulosAtual.find(e => e.includes('LAUDOS'))
    if (!moduloLaudosAtual)
      setQuantidadeLaudosIniciais('LAUDOS_1', precosModulos)
    if (moduloLaudosAtual) {
      const quantidadeLaudoAtual = modulosLaudosDisponiveis.find(
        e => e.tipo === moduloLaudosAtual
      ).quantidadeMinimaUsos
      const novoModuloLaudos = modulosLaudosDisponiveis.find(
        e => e.quantidadeMinimaUsos > quantidadeLaudoAtual
      )
      if (novoModuloLaudos)
        setQuantidadeLaudosIniciais(novoModuloLaudos.tipo, precosModulos)
    }
  }

  return {
    moduloDeLaudos,
    setModuloDeLaudos,
    handlerSelectLaudos,
    laudosMinMax,
    setupLaudosIniciais
  }
}
