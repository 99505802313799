import { Select } from '@prev/ui-kit'
import { Col, Row, Spin } from 'antd'
import { useContext } from 'react'
import { PlanoContext } from '../../context/plano/PlanoContext'
import { antIcon } from '../loading/Loading'

export const SelectParcelas = ({
  precos,
  onChange,
  numeroDeParcelas,
  ...props
}) => {
  const { carregandoPreco } = useContext(PlanoContext)

  if (carregandoPreco)
    return (
      <Row style={{ width: '100%' }}>
        <Spin indicator={antIcon} size={12} />
      </Row>
    )

  return (
    <Row>
      <Col span={24}>
        <Select
          {...props}
          style={{
            width: `500px`
          }}
          defaultValue={1}
          value={numeroDeParcelas || 1}
          placement="bottomLeft"
          getPopupContainer={() => document.body}
          onChange={onChange}
          options={precos?.parcelas?.map(
            ({
              parcelas,
              jurosPercentual,
              valorTotal,
              jurosValor,
              valorDaParcela
            }) => ({
              value: parcelas,
              label: `${Number(valorTotal).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              })} em ${parcelas}x de ${Number(valorDaParcela).toLocaleString(
                'pt-br',
                {
                  style: 'currency',
                  currency: 'BRL'
                }
              )} ${
                parcelas === 1
                  ? ` (sem juros)`
                  : ` (juros de ${jurosPercentual}%)`
              }`
            })
          )}
        />
      </Col>
    </Row>
  )
}
