import { formataDataComTexto } from './formataDatas'

export const gerarPayloadPagamento = (
  cartaoDeCredito,
  pricing,
  novaAssinatura,
  meioDePagamento,
  numeroDeParcelas
) => {
  const { uuid } = pricing
  const { intervalo, tiposDeModulo } = novaAssinatura
  const payload = {
    pricingUuid: uuid,
    meioDePagamento,
    numeroDeParcelas,
    intervalo: intervalo,
    tiposDeModulo: tiposDeModulo,
    aceiteRevogacaoTrial: true
  }
  if (cartaoDeCredito) Object.assign(payload, { cartaoDeCredito })
  return payload
}

export const geraLegendaPagamento = (assinaturaUsuario, assinaturaPricing) => {
  if (assinaturaUsuario.trial && assinaturaPricing.dataFimTrial) {
    return formataDataComTexto(assinaturaPricing.dataDaCobranca)
  }
  return formataDataComTexto(assinaturaPricing.dataDaProximaCobranca)
}
