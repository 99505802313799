import { getBrandByCardNumber } from '@prev/ui-kit'

export const validateCard = form => {
  if (!form)
    return {
      hasErrors: true,
      payload: form,
      message: 'Por favor, preencha todos os campos'
    }
  const [mes, ano] = form.dataDeVencimento.split(' / ')
  const payload = {
    nome: form.nome.trim(),
    dataDeVencimento: `${ano}-${mes}`.replaceAll(' ', ''), // 0000-00
    bandeira: form.bandeira
      ? form.bandeira.toUpperCase()
      : getBrandByCardNumber(form.numero).brand,
    numero: form.numero.replace(/\D/g, ''),
    cvv: form.cvv.replaceAll(' ', ''),
    numeroDeParcelas: form.numeroDeParcelas || 1
  }
  return { hasErrors: false, payload, message: 'ok' }
}
