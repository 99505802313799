export const removeModuloLaudos = novaAssinatura => {
  const { tiposDeModulo } = novaAssinatura
  const tiposDeModuloSemLaudos = tiposDeModulo.filter(
    e => !e.includes('LAUDOS')
  )
  const assinaturaAtualizada = {
    ...novaAssinatura,
    tiposDeModulo: [...tiposDeModuloSemLaudos]
  }
  return assinaturaAtualizada
}

//Deixei aqui mas não existe previsão de colocarmos um plano ilimitado por enquanto
export const adicionaModuloIlimitado = novaAssinatura => {
  const assinaturaAtualizada = removeModuloLaudos(novaAssinatura)
  assinaturaAtualizada.tiposDeModulo.push('LAUDOS_ILIMITADO')
  return assinaturaAtualizada
}

export const adicionaModuloLaudos = (
  tabelaDePrecoModulos,
  moduloLaudosSelecionado,
  novaAssinatura
) => {
  const { precosModulos } = tabelaDePrecoModulos
  const modulosLaudos = precosModulos.filter(m => m.tipo.includes('LAUDOS'))
  const modulosLaudosValue = modulosLaudos.find(
    m2 => m2.tipo === moduloLaudosSelecionado
  )?.tipo
  const assinaturaAtualizada = removeModuloLaudos(novaAssinatura)
  modulosLaudosValue &&
    assinaturaAtualizada.tiposDeModulo.push(modulosLaudosValue)
  return assinaturaAtualizada
}

export const encontraQuantidadeMaxima = precosModulos => {
  const modulosLaudos = precosModulos.filter(m => m.tipo.includes('LAUDOS'))
  const moduloComMaisUsos = modulosLaudos.reduce(
    (max, m) => (m.quantidadeMaximoUsos > max ? m.quantidadeMaximoUsos : max),
    0
  )
  return moduloComMaisUsos
}

export const verificaSeModuloLaudos = tipoModulo =>
  tipoModulo.includes('LAUDOS')
