/* eslint-disable react-hooks/exhaustive-deps */
import { SegmentContext } from "@prev/ui-kit";
import { useContext, useEffect } from "react";
import { verificarUpgrade, verificarDowngrade } from "../utils/checkPlanUpgrade";
import { getSegmentObject } from "../utils/getSegment";

export const useAnalytics = (assinaturaAtual, context) => {
  const { analytics, identified } = useContext(SegmentContext);

  useEffect(() => {
    identificarUsuarioAnalytics(assinaturaAtual);
  }, [assinaturaAtual]);

  const identificarUsuarioAnalytics = (user) => {
    if (!user || !analytics || identified) return;
    identificarUsuario(user);

    const url = window.location.href?.toUpperCase();
    if (url.includes(`ALTERAR`)) return eventoDataLayer("eec.iniciou_alteracao", user.assinatura);
    if (url.includes(`REATIVAR`)) return eventoDataLayer("eec.iniciou_reativacao", user.assinatura);
  };

  const identificarUsuario = (user) => {
    const { uuid } = user.usuario.uuid;
    analytics.identify(uuid, getSegmentObject(user));
  };

  const trackEvent = (eventName, eventProperties) => {
    if (!analytics || !analytics?.track) return;
    analytics.track(eventName, eventProperties);
  };

  const eventoDataLayer = (eventName, dadosGTM) => {
    if (!window.dataLayer) return;

    if (assinaturaAtual !== undefined) {
      window.dataLayer.push({
        event: eventName,
        plano: dadosGTM,
        info: {
          ...assinaturaAtual,
        },
      });
    } else {
      window.dataLayer.push({
        event: eventName,
        plano: dadosGTM,
      });
    }
  };

  const gerarDadosGtm = (precos) => {
    const { tabelaDePrecoModulos, ...restPlano } = precos;
    const dadosParaGTM = {
      valorCobrado: precos.proRata && precos.proRata.valor ? precos.proRata.valor : precos.valor,
      ...restPlano,
    };
    return dadosParaGTM;
  };

  const enviaEventoDataLayerAlteracao = (user, novaAssinatura, precos) => {
    const { assinatura } = user;
    const { intervalo: novoIntervalo } = novaAssinatura;
    const { intervalo: intervaloAtual } = assinatura;

    const dadosParaGTM = gerarDadosGtm(precos);
    eventoDataLayer("eec.alteracao_sucesso", dadosParaGTM);

    if (verificarDowngrade(assinatura, novaAssinatura, precos))
      eventoDataLayer("eec.alteracao_downgrade", dadosParaGTM);

    if (verificarUpgrade(assinatura, novaAssinatura, precos)) eventoDataLayer("eec.alteracao_upgrade", dadosParaGTM);
    if (novoIntervalo === "ANUAL" && intervaloAtual === "MENSAL") eventoDataLayer("eec.alteracao_anual", dadosParaGTM);
  };

  const enviaEventoDataLayerReativar = (user, novaAssinatura, precos) => {
    const { assinatura } = user;
    const { intervalo: novoIntervalo } = novaAssinatura;
    const { intervalo: intervaloAtual } = assinatura;
    const dadosParaGTM = gerarDadosGtm(precos);

    eventoDataLayer("eec.reativacao_sucesso", dadosParaGTM);
    if (verificarDowngrade(assinatura, novaAssinatura, precos))
      eventoDataLayer("eec.reativacao_downgrade", dadosParaGTM);

    if (verificarUpgrade(assinatura, novaAssinatura, precos)) eventoDataLayer("eec.reativacao_upgrade", dadosParaGTM);
    if (novoIntervalo === "ANUAL" && intervaloAtual === "MENSAL") eventoDataLayer("eec.reativacao_anual", dadosParaGTM);
  };

  return {
    trackEvent,
    eventoDataLayer,
    enviaEventoDataLayerAlteracao,
    enviaEventoDataLayerReativar,
  };
};
