import { Button, LoadingContext, Select, Text } from '@prev/ui-kit'
import Header from '../../components/header/Header'
import { PageContainer } from '../../styles'
import { useContext, useState } from 'react'
import { PlanoContext } from '../../context/plano/PlanoContext'
import { Col, Form, Modal, Row, Spin, message } from 'antd'
import useWindowSize from '../../hooks/useWindowSize'
import { OverviewRow } from '../resolverPendencias/pendencias/styles'
import { ParcelamentoError } from './ParcelamentoError'
import { ResumoParcelamento } from '../../components/parcelamento/ResumoParcelamento'
import { alterarParcelamento } from '../../services/faturas'
import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { formataDataJSX } from '../../utils/formataDatas'
import Swal from 'sweetalert2'
import { confirmParcelamentoText } from '../../utils/confirmModalText'

export const AlterarParcelameno = () => {
  const {
    setNumeroDeParcelas,
    numeroDeParcelas,
    assinaturaAtual,
    precos,
    carregandoPrecos
  } = useContext(PlanoContext)
  const { loading } = useContext(LoadingContext)
  const { width } = useWindowSize()
  const [success, setSuccess] = useState(false)
  const [form] = Form.useForm()

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 50, color: '#F26526' }} spin />
  )

  const returnDomain = () => {
    window.location.href = '/configuracoes/financeiro'
    return
  }

  if (!precos) return null
  if (precos && !precos?.parcelas)
    return (
      <ParcelamentoError
        errorMessage={'Não foi possível carregar as opções de parcelamento'}
      />
    )

  const proximaDatacobranca = () => {
    if (!precos) return
    const dataFormatada = formataDataJSX(
      precos?.assinatura?.dataDaProximaCobranca
    )
    return dataFormatada
  }

  const handleSubmit = async values => {
    try {
      const payload = {
        pricingUuid: precos.uuid,
        qtdParcelas: values.numeroDeParcelas
      }
      await alterarParcelamento(payload)
      setSuccess(true)
    } catch (err) {
      message.error('Não foi possível alterar o parcelamento, tente novamentes')
    }
  }

  return (
    <PageContainer
      style={{ padding: '20px', height: loading ? '100px' : '100%' }}
    >
      <Modal
        open={success}
        footer={false}
        onCancel={() => returnDomain()}
        onOk={() => returnDomain()}
      >
        <Row style={{ width: '100%' }} gutter={24}>
          <Col span={4}>
            <CheckCircleOutlined
              style={{ fontSize: '48px', color: '#00A881' }}
            />
          </Col>
          <Col span={20}>
            <Text emphasys size="lg" style={{ marginBottom: '15px' }}>
              Você alterou sua forma de pagamento com sucesso!
            </Text>
            <Text style={{ marginBottom: '15px' }}>
              A próxima cobrança será em {proximaDatacobranca()} no valor de{' '}
              <Text emphasys type="span">
                {numeroDeParcelas}x de{' '}
                {Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(
                  precos?.parcelas[numeroDeParcelas - 1].valorDaParcela
                )}
              </Text>
            </Text>
          </Col>
        </Row>
        <Row style={{ justifyContent: 'flex-end' }}>
          <Button onClick={() => returnDomain()}>Ok</Button>
        </Row>
      </Modal>
      <Header back captionOff />
      <Row type="flex">
        <OverviewRow type="flex" align="top" justify="space-between">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={15}
            xl={15}
            span={15}
            style={{ order: width > 768 ? 0 : 1 }}
          >
            <Text size="lg" emphasys style={{ marginBottom: '20px' }}>
              Alterar o pagamento da renovação do meu plano
            </Text>
            <Form onFinish={handleSubmit} form={form}>
              <Form.Item
                id="parcelamento-options"
                placeholder="Selecione o número de parcelas"
                aria-label="Opções de parcelamento"
                data-testid="select-numero-parcelas"
                style={{ width: '100%' }}
                name="numeroDeParcelas"
                rules={[
                  {
                    required: true,
                    message: 'Selecione o parcelamento'
                  }
                ]}
              >
                <Row>
                  <Col xs={24} sm={24} md={24} lg={15} xl={15} span={15}>
                    <Select
                      onChange={value => {
                        form.setFieldValue('numeroDeParcelas', value)
                        setNumeroDeParcelas(value)
                      }}
                      id="parcelamento-options"
                      placeholder="Selecione a forma de parcelamento"
                      style={{
                        width: `100%`
                      }}
                      placement="bottomLeft"
                      getPopupContainer={() => document.body}
                      options={precos?.parcelas?.map(
                        ({
                          parcelas,
                          jurosPercentual,
                          valorTotal,
                          jurosValor,
                          valorDaParcela
                        }) => ({
                          value: parcelas,
                          label: `${Number(valorTotal).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                          })} em ${parcelas}x de ${Number(
                            valorDaParcela
                          ).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                          })} ${
                            parcelas === 1
                              ? ` (sem juros)`
                              : ` (juros de ${jurosPercentual}%)`
                          }`
                        })
                      )}
                      label="Escolha a forma de pagamento"
                      aria-label="Opções de parcelamento"
                    />
                  </Col>
                </Row>
              </Form.Item>
              <Button
                style={{ marginBottom: '20px' }}
                onClick={() => {
                  form
                    .validateFields()
                    .then(result =>
                      Swal.fire({
                        title: 'Tem certeza?',
                        html: confirmParcelamentoText(numeroDeParcelas, precos),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3575AB',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não'
                      }).then(result => {
                        if (result.value) {
                          form.submit()
                        }
                      })
                    )
                    .catch(err => {
                      return
                    })
                }}
              >
                Salvar alterações
              </Button>
            </Form>

            <Text style={{ marginBottom: '20px' }} emphasys size="lg">
              O que é a "Data da próxima cobrança"?
            </Text>
            <Text style={{ marginBottom: '20px', color: '#949595' }}>
              É a data em que seu plano será renovado e cobrado futuramente caso
              você não cancele sua assinatura. Porém você pode cancelar sua
              assinatura a qualquer momento para evitar renovações futuras em
              até 03 cliques dentro da sua própria plataforma. Veja como é fácil
              cancelar clicando aqui.
            </Text>
          </Col>
          {<ResumoParcelamento />}
        </OverviewRow>
      </Row>
    </PageContainer>
  )
}
