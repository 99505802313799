/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react'
import { LoadingContext } from '../LoadingContext'
import { getFatura, pagarPendencia, getInfo } from '../../services/faturas'
import { validateCard } from '../../utils/validateCard'
import useWindowSize from '../../hooks/useWindowSize'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const useResolverPendencias = () => {
  const { setLoading } = useContext(LoadingContext)
  const { width } = useWindowSize()
  const [paymentMethod, setPaymentMethod] = useState()
  const [resume, setResume] = useState()
  const [pixSuccess, setPixSuccess] = useState(false)
  const [histModal, setHistModal] = useState(false)
  const [payment, setPayment] = useState(undefined)
  const [error, setError] = useState(undefined)
  const [success, setSuccess] = useState(false)
  const [assinaturaAtual, setAssinaturaAtual] = useState()
  const { executeRecaptcha } = useGoogleReCaptcha()

  useEffect(() => getAssinaturaAtual(), [])

  useEffect(() => getPrecos(), [])

  useEffect(() => {
    if (width > 768 && !paymentMethod) setPaymentMethod('CARTAO_DE_CREDITO')
  }, [width])

  const getAssinaturaAtual = async () => {
    const assinaturaAtual = await getInfo()
    const assinaturaAtualSemCasosOld = {
      ...assinaturaAtual,
      assinatura: {
        ...assinaturaAtual.assinatura,
        tiposDeModulo: assinaturaAtual.assinatura.tiposDeModulo.filter(
          modulos => modulos !== 'CASOS_OLD'
        )
      }
    }
    setAssinaturaAtual({ ...assinaturaAtualSemCasosOld })
  }

  const getPrecos = async () => {
    const faturas = await getFatura()
    const faturasNaoPagas = faturas.filter(e => e.status === 'PENDENTE')
    if (!faturasNaoPagas.length) {
      return setError({
        hideCaption: true,
        hideButtons: true,
        message: 'Não há faturas pendentes para serem pagas.'
      })
    }
    setResume(faturas[0])
  }

  useEffect(() => {
    setError(undefined)
    setPayment(undefined)
    if (paymentMethod === 'PIX') postPagarFatura()
  }, [paymentMethod])

  const postPagarFatura = async data => {
    const token = await executeRecaptcha('pagar_fatura_resolver_pendencia')
    try {
      setLoading(true)
      const payload = {
        meioDePagamento: paymentMethod,
        cartaoDeCredito: data,
        tokenFatura: resume.uuid
      }
      if (paymentMethod !== 'CARTAO_DE_CREDITO') {
        delete payload.cartaoDeCredito
        delete payload.numeroDeParcelas
      }
      if (paymentMethod === 'CARTAO_DE_CREDITO') {
        payload.cartaoDeCredito = validateCard(payload.cartaoDeCredito).payload
      }
      const response = await pagarPendencia(payload, token)
      setLoading(false)
      if (paymentMethod === 'BOLETO')
        return setPayment({ ...response.boleto, paymentMethod })
      if (paymentMethod === 'PIX')
        return setPayment({ ...response, paymentMethod })
      return setSuccess(true)
    } catch (erro) {
      handlePagarFaturaError(erro)
    }
  }

  const handlePagarFaturaError = erro => {
    const mensagemGenerica =
      'Ocorreu um erro ao realizar o pagamento. Tente novamente.'
    const descricaoErro = erro?.response?.data?.error_description
    setPaymentMethod(undefined)
    setLoading(false)
    if (paymentMethod === 'CARTAO_DE_CREDITO')
      return setError({ message: descricaoErro || mensagemGenerica })
    if (paymentMethod === 'PIX' || paymentMethod === 'BOLETO')
      setError({
        message: descricaoErro || mensagemGenerica,
        hideCaption: true
      })
  }

  return {
    assinaturaAtual,
    postPagarFatura,
    setPaymentMethod,
    setError,
    setPixSuccess,
    setHistModal,
    paymentMethod,
    resume,
    error,
    success,
    pixSuccess,
    histModal,
    payment
  }
}

export default useResolverPendencias
