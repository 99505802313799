import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

export const LoginContext = createContext({
  token: localStorage?.getItem("access_token_prev")
})

function LoginProvider({ children }) {
  const [token, setToken] = useState(localStorage?.getItem("access_token_prev"));

  useEffect( () => {    
    setToken(token)
  }, [token])

  return (
    <LoginContext.Provider value={{ token, setToken }}>
      {children}
    </LoginContext.Provider>
  )
}

LoginProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginProvider
