import { betweenTwoValues } from './prevModules'

export const removeModuloCasos = novaAssinatura => {
  const { tiposDeModulo } = novaAssinatura
  const tiposDeModuloSemCasos = tiposDeModulo.filter(e => !e.includes('CASOS'))
  const assinaturaAtualizada = {
    ...novaAssinatura,
    tiposDeModulo: [...tiposDeModuloSemCasos]
  }
  return assinaturaAtualizada
}

export const adicionaModuloIlimitado = novaAssinatura => {
  const assinaturaAtualizada = removeModuloCasos(novaAssinatura)
  assinaturaAtualizada.tiposDeModulo.push('CASOS_ILIMITADO')
  return assinaturaAtualizada
}

export const adicionaModuloCasos = (
  tabelaDePrecoModulos,
  numeroCasosSelecionado,
  novaAssinatura
) => {
  const { precosModulos } = tabelaDePrecoModulos
  const modulosCasos = precosModulos.filter(m => m.tipo.includes('CASOS'))
  const modulosCasosValue = modulosCasos.find(m2 =>
    betweenTwoValues(
      numeroCasosSelecionado,
      m2.quantidadeMinimaUsos,
      m2.quantidadeMaximoUsos
    )
  )
  const assinaturaAtualizada = removeModuloCasos(novaAssinatura)
  assinaturaAtualizada.tiposDeModulo.push(modulosCasosValue.tipo)
  return assinaturaAtualizada
}

export const encontraQuantidadeMaxima = precosModulos => {
  const modulosCasos = precosModulos.filter(m => m.tipo.includes('CASOS'))
  const moduloComMaisUsos = modulosCasos.reduce(
    (max, m) => (m.quantidadeMaximoUsos > max ? m.quantidadeMaximoUsos : max),
    0
  )
  return moduloComMaisUsos
}

export const verificaSeModuloCasos = tipoModulo => tipoModulo.includes('CASOS')
