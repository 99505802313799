import { FlipCard } from "@prev/ui-kit";
import { Col, Row } from "antd";
import React, { useState } from "react";
import CreditCardForm from "../../../components/resolverPendencias/cartaoCreditoForm/FormCartaoCredito";
import PaymentMethod from "../../../components/resolverPendencias/metodosDePagamento/MetodosDePagamento";
import useWindowSize from "../../../hooks/useWindowSize";

function CreditCard() {
  const { width } = useWindowSize();
  const [form, setForm] = useState({});
  const [flipped, setFlipped] = useState();

  return (
    <>
      <CreditCardForm setFlipped={setFlipped} setForm={setForm} form={form} />
      <Col
        style={{ order: width < 1024 ? 15 : 0 }}
        xs={24}
        sm={24}
        md={24}
        lg={7}
        xl={7}
        span={7}
      >
        <Row
          type="flex"
          gutter={[0, 44]}
          style={{ marginTop: "10%", minWidth: "335px" }}
        >
          {width > 1023 && (
            <FlipCard
              number={form.numero}
              cvv={form.cvv}
              name={form.nome}
              expiry={form.dataDeVencimento}
              isFlipped={flipped}
              style={{ marginBottom: "100px", width: "100%" }}
            />
          )}
          <PaymentMethod showTitle />
        </Row>
      </Col>
    </>
  );
}

export default CreditCard;
