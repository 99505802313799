import React from "react";
import { LockOutlined } from "@ant-design/icons";
import { Col } from "antd";
import { CustomRow } from "./styles";
import useWindowSize from "../../../hooks/useWindowSize";

function SafePayment() {
  const { width } = useWindowSize();
  return (
    <Col span={width < 1024 ? 24 : null}>
      <CustomRow type="flex" align="center">
        <LockOutlined style={{ color: "#00A881" }} />
        <div className="ui-kit font-size-sm">
          SUAS INFORMAÇÕES ESTÃO SEGURAS CONOSCO
        </div>
      </CustomRow>
    </Col>
  );
}

export default SafePayment;
