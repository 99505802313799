/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { BASE_MODULES } from '../context/plano/usePlano'
import { getInfo, getPricing } from '../services/faturas'

export const DEFAULT_ASSINATURA = {
  intervalo: '',
  tiposDeModulo: BASE_MODULES
}

export const useAssinatura = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [novaAssinatura, setNovaAssinatura] = useState(DEFAULT_ASSINATURA)
  const [assinaturaAtual, setAssinaturaAtual] = useState()

  const checkPath = () => {
    const url = window?.location?.href?.toUpperCase()
    if (url.includes('ALTERAR')) return 'ALTERAR'
    if (url.includes('REATIVAR')) return 'REATIVAR'
  }

  const carregaInformacoesUsuario = async () => {
    const informacoesUsuario = await getInfo()
    const informacoesSemCasosOld = {
      ...informacoesUsuario,
      assinatura: {
        ...informacoesUsuario.assinatura,
        tiposDeModulo: informacoesUsuario.assinatura.tiposDeModulo.filter(
          modulos => modulos !== 'CASOS_OLD'
        )
      }
    }
    const tipoEvento = checkPath() === 'ALTERAR' ? 'ALTERACAO' : 'REATIVACAO'
    const { assinatura: assinaturaAtual } = informacoesSemCasosOld
    const precosPayload = {
      intervalo: assinaturaAtual.intervalo,
      tiposDeModulo: assinaturaAtual.tiposDeModulo,
      tipoDeEvento: tipoEvento
    }
    const userPricing = await getPricing(precosPayload)
    setAssinaturaAtual({
      ...informacoesSemCasosOld,
      precos: {
        ...userPricing
      }
    })
  }

  const setupNovaAssinatura = precos => {
    const { tiposDeModulo: modulosUsuario } = assinaturaAtual.assinatura
    const tiposDeModulo = [...new Set([...modulosUsuario, ...BASE_MODULES])]
    const { intervalo: intervaloAtual } = assinaturaAtual.assinatura
    const intervaloQuery = searchParams.get('intervalo')?.toUpperCase()
    const moduloQuery = searchParams.get('modulo')?.toUpperCase()
    if (moduloQuery && moduloQuery !== 'CASOS' && moduloQuery !== 'LAUDOS')
      tiposDeModulo.push(moduloQuery)

    setIntervalo(intervaloQuery || intervaloAtual)
    setModulos(tiposDeModulo)
    setupNovoModuloConsumivel(tiposDeModulo, precos, moduloQuery)
  }

  const setupNovoModuloConsumivel = (tiposDeModulo, precos, modulo) => {
    if (!modulo) return
    const { tabelaDePrecoModulos } = precos
    const moduloQuery = searchParams?.get('modulo')?.toUpperCase()
    if (moduloQuery !== modulo) return
    const modulosAtual = tiposDeModulo
    const { precosModulos } = tabelaDePrecoModulos
    const modulosDisponiveis = precosModulos.filter(e =>
      e.tipo.includes(modulo)
    )
    const moduloAtual = modulosAtual.find(e => e.includes(modulo))
    if (!moduloAtual) {
      const modulo_1 = `${modulo}_1`
      setNovaAssinatura(a => ({
        ...a,
        tiposDeModulo: [...modulosAtual, modulo_1]
      }))
    }
    if (moduloAtual) {
      const quantidadeAtual = modulosDisponiveis.find(
        e => e.tipo === moduloAtual
      ).quantidadeMinimaUsos
      const novoModulo = modulosDisponiveis.find(
        e => e.quantidadeMinimaUsos > quantidadeAtual
      )
      if (novoModulo) {
        const modulosAtualizados = modulosAtual.filter(e => !e.includes(modulo))
        setNovaAssinatura(a => ({
          ...a,
          tiposDeModulo: [...modulosAtualizados, novoModulo?.tipo]
        }))
      }
    }
  }

  const setIntervalo = intervalo => {
    setNovaAssinatura(assinaturaAntiga => ({
      ...assinaturaAntiga,
      intervalo
    }))
  }

  const setModulos = modulos => {
    modulos = modulos.map(modulo =>
      modulo === 'CASOS_ILIMITADO' ? 'CASOS_7' : modulo
    )
    setNovaAssinatura(assinaturaAntiga => ({
      ...assinaturaAntiga,
      tiposDeModulo: modulos
    }))
  }

  useEffect(() => {
    carregaInformacoesUsuario()
  }, [])

  useEffect(() => {
    if (assinaturaAtual) setupNovaAssinatura(assinaturaAtual.precos)
  }, [assinaturaAtual])

  return {
    novaAssinatura,
    assinaturaAtual,
    setAssinaturaAtual,
    setNovaAssinatura,
    setupNovaAssinatura
  }
}
