import React, { useContext } from "react";
import Header from "../../../components/header/Header";
import { PageContainer } from "../../../styles";
import { Button, Text } from "@prev/ui-kit";
import { Col, Row } from "antd";
import { BarcodeOutlined, CopyOutlined, PrinterOutlined } from "@ant-design/icons";
import Barcode from "react-barcode";
import PaymentMethod from "../../../components/resolverPendencias/metodosDePagamento/MetodosDePagamento";
import { formataDataJSX } from "../../../utils/formataDatas";
import useWindowSize from "../../../hooks/useWindowSize";
import { ResolvePendenciesContext } from "../../../context/resolverPendencias/ResolvePendenciesContext";

function Boleto() {
  const { payment } = useContext(ResolvePendenciesContext);
  const { width } = useWindowSize();
  const [copied, setCopied] = React.useState(false);
  const clipboardCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
  };

  const barCodeOptions = {
    width: 1,
    height: 50,
    format: "ITF",
    displayValue: false,
    fontOptions: "",
    font: "monospace",
    textAlign: "center",
    textPosition: "bottom",
    textMargin: 2,
    fontSize: 20,
    background: "#ffffff",
    lineColor: "#000000",
    marginBottom: 10,
  };
  return (
    <PageContainer>
      <Header captionOff />
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={24}
        span={24}
        style={{
          textAlign: "center",
          overflowWrap: "break-word",
        }}
      >
        <Text size="xl" emphasys>
          Boleto gerado com sucesso!
        </Text>
        <Text color="#BDBDBD">O vencimento do boleto é no dia {formataDataJSX(payment.dataDeVencimento)}</Text>
        {width > 1023 && (
          <Text style={{ padding: "20px 0px" }}>
            Utilize o número do código de barras para realizar o pagamento da sua fatura
          </Text>
        )}
        {width > 1023 ? (
          <>
            <Barcode
              value={payment.codigoDeBarras}
              {...barCodeOptions}
              id="img-codigo-de-barras"
              data-testid="img-codigo-de-barras"
            />
            <Text
              style={{
                marginBottom: "10px",
              }}
              id="boleto.codigoDeBarras"
              data-testid="boleto.codigoDeBarras"
            >
              {payment.codigoDeBarras}
            </Text>
          </>
        ) : (
          <div>
            <Text
              style={{
                padding: "30px 20px",
                backgroundColor: "#EEEEEE",
                margin: "15px 0",
              }}
              id="boleto.codigoDeBarras"
              data-testid="boleto.codigoDeBarras"
            >
              {payment.codigoDeBarras}
            </Text>
          </div>
        )}
        <Row align="center" style={{ rowGap: "20px", columnGap: "20px", paddingBottom: "40px" }}>
          {width > 1023 ? (
            <>
              <Text
                style={{ cursor: "pointer" }}
                onClick={() => clipboardCopy(payment.codigoDeBarras)}
                size="sm"
                color="#3575AB"
                aria-label="Copiar código de barras"
                id="btn-copiar-codigo-barras"
                data-testid="btn-copiar-codigo-barras"
              >
                <CopyOutlined style={{ paddingRight: "5px" }} />
                Copiar código de barras
              </Text>
              <Text
                id="btn-imprimir-boleto"
                style={{ cursor: "pointer" }}
                onClick={() => window.open(payment.urlDeImpressao, "_blank")}
                size="sm"
                color="#3575AB"
                aria-label="Imprimir boleto"
                data-testid="btn-imprimir-boleto"
              >
                <PrinterOutlined style={{ paddingRight: "5px" }} />
                Imprimir boleto
              </Text>
            </>
          ) : (
            <Button
              onClick={() => clipboardCopy(payment.codigoDeBarras)}
              icon={<BarcodeOutlined />}
              size="large"
              style={{ width: "100%" }}
              aria-label="Copiar código de barras"
              data-testid="btn-copiar-codigo-barras"
              id="btn-copiar-codigo-barras"
            >
              {copied ? "Copiado !" : "Copiar código de barras"}
            </Button>
          )}
        </Row>
        <PaymentMethod justifyContent="center" direction="column" showTitle />
      </Col>
    </PageContainer>
  );
}

export default Boleto;
