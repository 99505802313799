import React, { useContext } from "react";
import { Button, Text } from "@prev/ui-kit";
import { Col, Row } from "antd";
import { BarcodeOutlined, CopyOutlined, PrinterOutlined } from "@ant-design/icons";
import Barcode from "react-barcode";
import useWindowSize from "../../hooks/useWindowSize";
import { formataDataJSX } from "../../utils/formataDatas";
import { PlanoContext } from "../../context/plano/PlanoContext";

function BoletoReativar() {
  const { pagamento } = useContext(PlanoContext);
  const { width } = useWindowSize();
  const [copied, setCopied] = React.useState(false);

  const clipboardCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
  };

  const barCodeOptions = {
    width: 1,
    height: 50,
    format: "ITF",
    displayValue: false,
    fontOptions: "",
    font: "monospace",
    textAlign: "center",
    textPosition: "bottom",
    textMargin: 2,
    fontSize: 20,
    background: "#ffffff",
    lineColor: "#000000",
    marginBottom: 10,
  };

  if (!pagamento || !pagamento.boleto) return null;

  return (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={24}
      xl={24}
      span={24}
      style={{
        textAlign: "center",
        overflowWrap: "break-word",
      }}
    >
      <Text size="lg">O vencimento do boleto é no dia {formataDataJSX(pagamento.boleto.dataDeVencimento)}</Text>
      {width > 1023 && (
        <Text style={{ padding: "20px 0px" }}>
          Utilize o número do código de barras para realizar o pagamento da sua fatura
        </Text>
      )}
      {width > 1023 ? (
        <>
          <Barcode
            value={pagamento.boleto.codigoDeBarras}
            {...barCodeOptions}
            id="img-codigo-de-barras"
            data-testid="img-codigo-de-barras"
          />
          <Text
            style={{
              marginBottom: "10px",
            }}
            id="boleto.codigoDeBarras"
            data-testid="boleto.codigoDeBarras"
          >
            {pagamento.boleto.codigoDeBarras}
          </Text>
        </>
      ) : (
        <div>
          <Text
            style={{
              padding: "30px 20px",
              backgroundColor: "#EEEEEE",
              margin: "15px 0",
            }}
            id="boleto.codigoDeBarras"
            data-testid="boleto.codigoDeBarras"
          >
            {pagamento.boleto.codigoDeBarras}
          </Text>
        </div>
      )}
      <Row align="center" style={{ rowGap: "20px", columnGap: "20px", paddingBottom: "40px" }}>
        {width > 1023 ? (
          <>
            <Text
              style={{ cursor: "pointer" }}
              onClick={() => clipboardCopy(pagamento.boleto.codigoDeBarras)}
              size="sm"
              color="#3575AB"
              aria-label="Copiar código de barras"
              id="btn-copiar-codigo-barras"
              data-testid="btn-copiar-codigo-barras"
            >
              <CopyOutlined style={{ paddingRight: "5px" }} />
              Copiar código de barras
            </Text>
            <Text
              id="btn-imprimir-boleto"
              style={{ cursor: "pointer" }}
              onClick={() => window.open(pagamento.boleto.urlDeImpressao, "_blank")}
              size="sm"
              color="#3575AB"
              aria-label="Imprimir boleto"
              data-testid="btn-imprimir-boleto"
            >
              <PrinterOutlined style={{ paddingRight: "5px" }} />
              Imprimir boleto
            </Text>
          </>
        ) : (
          <Button
            onClick={() => clipboardCopy(pagamento.boleto.codigoDeBarras)}
            icon={<BarcodeOutlined />}
            size="large"
            style={{ width: "100%" }}
            aria-label="Copiar código de barras"
            data-testid="btn-copiar-codigo-barras"
            id="btn-copiar-codigo-barras"
          >
            {copied ? "Copiado !" : "Copiar código de barras"}
          </Button>
        )}
      </Row>
    </Col>
  );
}

export default BoletoReativar;
