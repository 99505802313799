/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useSearchParams } from 'react-router-dom'
import { LoadingContext } from '../context/LoadingContext'
import {
  alterarPlano,
  getPricing,
  pagarPendencia,
  reativarPlano
} from '../services/faturas'
import { checkGtmEvent } from '../utils/checkPlanUpgrade'
import { geraLegendaPagamento, gerarPayloadPagamento } from '../utils/pagamento'

export const usePagamento = (
  novaAssinatura,
  AnalyticsService,
  assinaturaAtual
) => {
  const [meioDePagamento, setMeioDePagamento] = useState('CARTAO_DE_CREDITO')
  const [pagamento, setPagamento] = useState()
  const [sucessoPix, setSucessoPix] = useState()
  const [carregandoPreco, setCarregandoPreco] = useState(false)
  const [erroPagamento, setErroPagamento] = useState()
  const [sucessoPagamento, setSucessoPagamento] = useState()
  const [precos, setPrecos] = useState()
  const [searchParams, setSearchParams] = useSearchParams()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { setLoading } = useContext(LoadingContext)
  const [numeroDeParcelas, setNumeroDeParcelas] = useState(1)

  const [informacoesTrial, setInformacoesTrial] = useState({
    aceiteRevogacaoTrial: false,
    mostrarModalTrial: false,
    callback: undefined
  })

  const resetaInformacoesPagamento = () => {
    setPagamento()
    setMeioDePagamento('CARTAO_DE_CREDITO')
    setNumeroDeParcelas(1)
  }

  const handleMudancaPagamento = async () => {
    setErroPagamento(undefined)
    setSucessoPagamento(undefined)
    setPagamento()
    await getPrecos(novaAssinatura)
    const tipoDeMudanca = checkPath()
    if (meioDePagamento !== 'CARTAO_DE_CREDITO' && tipoDeMudanca === 'REATIVAR')
      postReativarPlano()
    if (meioDePagamento !== 'CARTAO_DE_CREDITO' && tipoDeMudanca === 'ALTERAR')
      postAlterarPlano()
  }

  const checkPath = () => {
    const url = window?.location?.href?.toUpperCase()
    if (url.includes('ALTERAR')) return 'ALTERAR'
    if (url.includes('REATIVAR')) return 'REATIVAR'
  }

  const handlerPostPlano = async cartaoDeCredito => {
    const tipoDeMudanca = checkPath()
    if (tipoDeMudanca === 'ALTERAR') {
      return postAlterarPlano(cartaoDeCredito)
    }
    if (tipoDeMudanca === 'REATIVAR') {
      return postReativarPlano(cartaoDeCredito)
    }
  }

  const postAlterarPlano = async (cartaoDeCredito, aceiteTrial) => {
    try {
      setLoading(true)
      const trial = assinaturaAtual.assinatura.trial
      if (trial && !informacoesTrial.aceiteRevogacaoTrial && !aceiteTrial) {
        setLoading(false)
        return setInformacoesTrial(informacoesAntigas => ({
          ...informacoesAntigas,
          mostrarModalTrial: true,
          callback: () => postAlterarPlano(cartaoDeCredito, true)
        }))
      }
      const token = await executeRecaptcha('alterarplano_cartao_pagar')
      const tokenFatura = searchParams.get('uuid')
      const payload = gerarPayloadPagamento(
        cartaoDeCredito,
        precos,
        novaAssinatura,
        meioDePagamento,
        meioDePagamento === 'CARTAO_DE_CREDITO' &&
          novaAssinatura.intervalo === 'ANUAL'
          ? cartaoDeCredito?.numeroDeParcelas || numeroDeParcelas
          : 1
      )
      if (!assinaturaAtual?.assinatura?.trial)
        delete payload.aceiteRevogacaoTrial
      let response
      tokenFatura
        ? (response = await pagarPendencia({ ...payload, tokenFatura }, token))
        : (response = await alterarPlano(payload, token))
      if (meioDePagamento === 'CARTAO_DE_CREDITO')
        AnalyticsService.trackEvent('Alterando plano com cartão de crédito')
      setLoading(false)
      handlePosPost(response)
    } catch (err) {
      setLoading(false)
      handleErroPost(err)
    }
  }

  const postReativarPlano = async (cartaoDeCredito, aceiteTrial) => {
    try {
      setLoading(true)
      const trial = assinaturaAtual.assinatura.trial
      if (trial && !informacoesTrial.aceiteRevogacaoTrial && !aceiteTrial) {
        setLoading(false)
        return setInformacoesTrial(informacoesAntigas => ({
          ...informacoesAntigas,
          mostrarModalTrial: true,
          callback: () => postReativarPlano(cartaoDeCredito, true)
        }))
      }
      const token = await executeRecaptcha('reativar_assinatura')
      const payload = gerarPayloadPagamento(
        cartaoDeCredito,
        precos,
        novaAssinatura,
        meioDePagamento,
        meioDePagamento === 'CARTAO_DE_CREDITO' &&
          novaAssinatura.intervalo === 'ANUAL'
          ? cartaoDeCredito?.numeroDeParcelas || numeroDeParcelas
          : 1
      )
      if (!assinaturaAtual?.assinatura?.trial)
        delete payload.aceiteRevogacaoTrial
      const response = await reativarPlano(payload, token)
      setLoading(false)
      AnalyticsService.trackEvent('Assinatura reativada com sucesso')
      handlePosPost(response)
    } catch (err) {
      handleErroPost(err)
    }
  }

  const handlePosPost = response => {
    gerarMetricasAnalytics(response)
    if (response.status === 'SUCESSO') return setSucessoPagamento(true)
    if (!Object.keys(response).length) return renderizarSemCobranca()
    if (['BOLETO', 'PIX'].includes(meioDePagamento))
      return renderizarPagamento(response)
  }

  const handleErroPost = erro => {
    const descricaoErro = erro?.response?.data?.error_description
    const mensagemGenerica = 'Ocorreu um erro ao alterar o plano.'
    setLoading(false)
    setErroPagamento({
      message: descricaoErro || mensagemGenerica,
      hideCaption: meioDePagamento !== 'CARTAO_DE_CREDITO'
    })
  }

  const getPrecos = async novaAssinatura => {
    const tipoDeEvento = checkPath() === 'ALTERAR' ? 'ALTERACAO' : 'REATIVACAO'
    const { intervalo, tiposDeModulo } = novaAssinatura
    if (!intervalo || !tiposDeModulo) return
    setCarregandoPreco(true)
    const userPricing = await getPricing({
      intervalo: intervalo,
      tiposDeModulo: [...tiposDeModulo],
      tipoDeEvento: tipoDeEvento,
      tipoDeMeioDePagamento: meioDePagamento
    })
    setPrecos({ ...userPricing })
    setCarregandoPreco(false)
  }

  const gerarMetricasAnalytics = response => {
    const url = window?.location?.href?.toUpperCase()
    if (checkGtmEvent(response) && url.includes('REATIVAR')) {
      return AnalyticsService.enviaEventoDataLayerReativar(
        assinaturaAtual,
        novaAssinatura,
        precos
      )
    }
    if (checkGtmEvent(response) && url.includes('ALTERAR')) {
      return AnalyticsService.enviaEventoDataLayerAlteracao(
        assinaturaAtual,
        novaAssinatura,
        precos
      )
    }
  }

  const renderizarSemCobranca = () => {
    const { assinatura: assinaturaUsuario } = assinaturaAtual
    const { assinatura: assinaturaPricing } = precos
    setSucessoPagamento({
      message: null,
      caption: `Nenhuma cobrança será realizada até o dia ${geraLegendaPagamento(
        assinaturaUsuario,
        assinaturaPricing
      )}`
    })
  }

  const renderizarPagamento = async postResponse => {
    const { boleto } = postResponse
    setPagamento({ ...postResponse, meioDePagamento })
    if (boleto)
      return AnalyticsService.trackEvent(
        'Gerando boleto para pagamento de plano'
      )
    return AnalyticsService.trackEvent(
      'Gerando codigo PIX para pagamento de plano'
    )
  }

  useEffect(() => {
    handleMudancaPagamento()
  }, [meioDePagamento])

  useEffect(() => {
    getPrecos(novaAssinatura)
    resetaInformacoesPagamento()
  }, [novaAssinatura])

  return {
    meioDePagamento,
    pagamento,
    sucessoPagamento,
    sucessoPix,
    carregandoPreco,
    erroPagamento,
    precos,
    informacoesTrial,
    numeroDeParcelas,
    setNumeroDeParcelas,
    getPrecos,
    setInformacoesTrial,
    setSucessoPix,
    postReativarPlano,
    setPagamento,
    setMeioDePagamento,
    setCarregandoPreco,
    handleMudancaPagamento,
    resetaInformacoesPagamento,
    setSucessoPagamento,
    setErroPagamento,
    postAlterarPlano,
    handlerPostPlano
  }
}
