export const verificarUpgrade = (
  assinaturaAtual,
  assinaturaNova,
  dadosPlano
) => {
  let valorAssinaturaAtual = assinaturaAtual.tiposDeModulo.reduce(
    (prev, current) => {
      return prev + getModulePrice(current, dadosPlano).valorBase
    },
    0
  )

  let valorAssinaturaAlteracao = assinaturaNova.tiposDeModulo.reduce(
    (prev, current) => {
      return prev + getModulePrice(current, dadosPlano).valorBase
    },
    0
  )
  return valorAssinaturaAlteracao > valorAssinaturaAtual
}

export const verificarDowngrade = (
  assinaturaAtual,
  assinaturaNova,
  dadosPlano
) => {
  let valorAssinaturaAtual = assinaturaAtual.tiposDeModulo.reduce(
    (prev, current) => {
      return prev + getModulePrice(current, dadosPlano).valorBase
    },
    0
  )

  let valorAssinaturaAlteracao = assinaturaNova.tiposDeModulo.reduce(
    (prev, current) => {
      return prev + getModulePrice(current, dadosPlano).valorBase
    },
    0
  )
  return valorAssinaturaAlteracao < valorAssinaturaAtual
}

const getModulePrice = (modulo, dadosPlano) => {
  if (dadosPlano && dadosPlano.tabelaDePrecoModulos.precosModulos) {
    const modulos = dadosPlano.tabelaDePrecoModulos.precosModulos.map(
      item => item.tipo
    )

    const precoModulo = modulos.find(module => module.tipo === modulo)
    const moduleInfo = getModule(modulo)

    return { ...moduleInfo, ...precoModulo }
  }
}

const getModule = key => list.filter(item => item.tipo === key)[0]

const list = [
  {
    tipo: 'CALCULO_PETICOES',
    name: 'PLANO BASE',
    description:
      'Faça cálculos diretamente a partir do CNIS, saiba quais benefícios estão disponíveis e receba recomendações de petições que venceram casos reais.'
  },
  {
    tipo: 'CATALOGO',
    name: 'Diretório de advogados',
    description:
      'Apareça no Diretório de Advogados do Previdenciarista e divulgue seu escritório para as mais de 650 mil visitas de clientes em potencial que recebemos todos os meses.',
    url: 'https://www.previdenciarista.com/diretorio-de-advogados/',
    icon: 'file-search'
  },
  {
    tipo: 'CASOS',
    name: 'Casos',
    description:
      'Selecione entre milhares de casos previdenciários enviados todos os dias por pessoas no Brasil inteiro e fale diretamente com o segurado.',
    url: 'https://www.previdenciarista.com/casos-previdenciarios/',
    icon: 'build'
  }
]

export const checkGtmEvent = response => {
  const { status } = response
  return !Object.keys(response).length || status === 'SUCESSO'
}
