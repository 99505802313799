import { Card, Text, Ajuda } from '@prev/ui-kit'
import { Col, Row, Spin } from 'antd'
import React, { useContext } from 'react'
import useWindowSize from '../../hooks/useWindowSize'
import { findModule } from '../../utils/prevModules'
import ResumoNovoPlano from './ResumoNovoPlano.jsx'
import { antIcon } from '../loading/Loading'
import { formataDataJSX } from '../../utils/formataDatas'
import { CustomCol } from '../resolverPendencias/resumo/styles'
import { PlanoContext } from '../../context/plano/PlanoContext'
import { ajudasText } from '../../utils/ajudasTexto.js'

function Resumo() {
  const { width } = useWindowSize()
  const { assinaturaAtual, carregandoPreco } = useContext(PlanoContext)

  const valorModulosAtual = assinaturaAtual.assinatura.tiposDeModulo.reduce(
    (acc, current) => {
      return (acc += findModule(
        current,
        assinaturaAtual.precos.tabelaDePrecoModulos.precosModulos
      ).valorLiquido)
    },
    0
  )

  if (assinaturaAtual)
    return (
      <CustomCol xs={24} sm={24} md={24} lg={8} xl={8} span={8}>
        {carregandoPreco && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              position: 'absolute',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 9999999
            }}
          >
            <Spin indicator={antIcon} />
          </div>
        )}
        <Card
          style={{
            margin: 0,
            marginBottom: width < 1024 ? '20px' : 0,
            width: '100%',
            opacity: carregandoPreco ? 0 : 1
          }}
          data-testid="sidebar_resumo_plano_atual"
        >
          <Text
            size="lg"
            style={{ paddingBottom: '15px', display: 'flex' }}
            emphasys
            type="span"
          >
            Resumo do plano atual{' '}
            <Ajuda
              itens={ajudasText}
              page={'resumoAtual'}
              element={'planoAtual'}
              propsPopover={{ autoAdjustOverflow: true }}
            />
          </Text>

          <Col>
            <Text style={{ marginBottom: '10px' }} emphasys>
              Plano
            </Text>
            <Row type="flex" justify="space-between">
              <Text
                style={{ marginBottom: '5px' }}
                color="#949595"
                id="atual_plano"
                data-testid="atual_plano"
              >
                {assinaturaAtual.assinatura.intervalo}
              </Text>
              <Text
                style={{ marginBottom: '5px' }}
                color="#949595"
                id="atual_valor"
                data-testid="atual_valor"
              >
                {Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(valorModulosAtual)}
              </Text>
            </Row>
            <Row type="flex" justify="space-between">
              <Text color="#949595">
                De{' '}
                <span
                  id="atual_periodo_inicio"
                  data-testid="atual_periodo_inicio"
                >
                  {formataDataJSX(assinaturaAtual.assinatura.periodo.inicio)}
                </span>{' '}
                até{' '}
                <span id="atual_periodo_fim" data-testid="atual_periodo_fim">
                  {formataDataJSX(assinaturaAtual.assinatura.periodo.fim)}
                </span>
              </Text>
            </Row>
          </Col>
        </Card>
        <ResumoNovoPlano carregandoPreco={carregandoPreco} />
      </CustomCol>
    )
}

export default Resumo
