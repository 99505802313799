import React, { useContext, useEffect } from 'react'
import { PageContainer } from '../../../styles'
import Header from '../../../components/header/Header'
import Resume from '../../../components/resolverPendencias/resumo/Resumo'
import { OverviewRow } from './styles'
import CreditCard from '../cartaoDeCredito/CartaoDeCredito'
import Pix from '../pix/Pix'
import BankSlip from '../gerarBoleto/GerarBoleto'
import useWindowSize from '../../../hooks/useWindowSize'
import { CustomCol } from '../../../components/resolverPendencias/resumo/styles'
import { Row } from 'antd'
import Boleto from '../boleto/Boleto'
import Success from '../sucesso/Sucesso'
import HistModal from '../../../components/resolverPendencias/modalTentativas/ModalTentativas'
import { ResolvePendenciesContext } from '../../../context/resolverPendencias/ResolvePendenciesContext'
import FailedPage from '../erro/Error'

import PaymentMethod from '../../../components/resolverPendencias/metodosDePagamento/MetodosDePagamento'
import { Text } from '@prev/ui-kit'

function Pendencies() {
  const { paymentMethod, success, error, payment, resume } = useContext(
    ResolvePendenciesContext
  )

  const { width } = useWindowSize()

  useEffect(() => {
    const viewport_meta = document.getElementById('viewport-meta')
    if (viewport_meta) {
      viewport_meta.setAttribute('content', 'width=device-width')
    }
  }, [])

  if (payment && payment.paymentMethod === 'BOLETO') return <Boleto />
  if (success) return <Success />
  if (error) return <FailedPage />
  if (!resume) return null

  return (
    <PageContainer>
      <Header />
      <Row type="flex">
        <HistModal />
        <OverviewRow type="flex" align="top" justify="space-between">
          {paymentMethod === 'CARTAO_DE_CREDITO' && <CreditCard />}
          {paymentMethod === 'BOLETO' && <BankSlip />}
          {payment && payment.paymentMethod === 'PIX' && <Pix />}
          {!(paymentMethod === 'PIX' && width < 1024) && (
            <CustomCol xs={24} sm={24} md={24} lg={7} xl={7} span={7}>
              <Resume />
            </CustomCol>
          )}
          {!paymentMethod && (
            <CustomCol xs={24} sm={24} md={24} lg={17} xl={17} span={17}>
              <Text emphasys size="lg" style={{ marginBottom: '20px' }}>
                Escolha o método de pagamento
              </Text>
              {!paymentMethod && <PaymentMethod emphasys />}
            </CustomCol>
          )}
        </OverviewRow>
      </Row>
    </PageContainer>
  )
}

export default Pendencies
