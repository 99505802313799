import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import useResolverPendencias from './useResolverPendencias'

export const ResolvePendenciesContext = createContext({})

function ResolvePendenciesProvider({ children }) {
  const resolverPendenciaValues = useResolverPendencias()

  return (
    <ResolvePendenciesContext.Provider value={{ ...resolverPendenciaValues }}>
      {children}
    </ResolvePendenciesContext.Provider>
  )
}

ResolvePendenciesProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default ResolvePendenciesProvider
