import { Col, Row } from "antd";
import styled from "styled-components";

export const ResumeCol = styled(Col)`
  display: flex;
  align-self: flex-end;
`;

export const OverviewRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  order: 5;
`;

export const FlexRow = styled(Row)`
  display: flex;
  flex-direction: column;
  row-gap: 100px;
  column-gap: 100px;
  width: 100%;
`;
