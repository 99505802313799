import { Col, Row } from "antd";
import styled from "styled-components";

export const CustomCol = styled(Col)`
  padding: 0, 20px, 0, 20px;
  border-radius: 8px;
  align-self: flex-start;
  align-items: center;
  order: 0;
`;

export const CustomRow = styled(Row)`
  display: flex;
`;
