const getToken = (paramName = "access_token_prev") => {
  if (localStorage) {
    return localStorage.getItem(paramName)
  }
  return null;
};

const storeToken = (token, paramName = "access_token_prev") => {
  if (localStorage) {
    localStorage.setItem(paramName, token)
  } else {
    console.error("localStorage error");
  }
};

const removeToken = () => {
  if (localStorage) {
    localStorage.removeItem("access_token_prev");
  } else {
    console.error("localStorage error");
  }
};

const onlyNumbers = (value) => value.replace(/[^0-9]/g, "");

export { getToken, storeToken, onlyNumbers, removeToken };
