import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Loading from "../components/loading/Loading";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export const LoadingContext = createContext({});

function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) return disableBodyScroll(document);
    return enableBodyScroll(document);
  }, [loading]);

  return (
    <LoadingContext.Provider value={{ setLoading, loading }}>
      {children}
      <Loading loading={loading} />
    </LoadingContext.Provider>
  );
}

LoadingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoadingProvider;
