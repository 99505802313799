import { Button } from "@prev/ui-kit";
import { Row } from "antd";
import styled from "styled-components";

export const FlexRow = styled(Row)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: ${(props) => props.justifycontent || "space-between"};
  align-items: center;
  row-gap: 20px;
  column-gap: 20px;
`;

export const PaymentButtons = styled(Button)`
  width: 320px;
  @media (max-width: 1023px) {
    width: 100%;
  }
`;
