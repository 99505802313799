import {
  BarcodeOutlined,
  CreditCardOutlined,
  QrcodeOutlined
} from '@ant-design/icons'
import {
  Button,
  Card,
  SegmentContext,
  Text,
  getBrandByCardNumber
} from '@prev/ui-kit'
import { Col, Row } from 'antd'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { PlanoContext } from '../../context/plano/PlanoContext'
import { confirmModalText } from '../../utils/confirmModalText'
import TermosDeUso from '../termosDeUso/TermosDeUso'
import AlterarPlanoBoleto from './BoletoReativar'
import AlterarCartaoDeCredito from './CartaoDeCredito'
import AlterarPlanoPix from './PixReativar'

const PagamentoReativar = () => {
  const {
    assinaturaAtual,
    meioDePagamento,
    postReativarPlano,
    setMeioDePagamento,
    novaAssinatura,
    precos,
    AnalyticsService,
    setNumeroDeParcelas,
    numeroDeParcelas
  } = useContext(PlanoContext)
  const [editCreditCard, setEditCreditCard] = useState(false)
  const [form, setForm] = useState()
  const { analytics } = useContext(SegmentContext)

  const regraMostrarModalConfirmaPagto =
    novaAssinatura.intervalo === 'ANUAL' &&
    precos &&
    (precos.proRata ||
      moment(precos.assinatura.periodo.dataDaCobranca).isSameOrBefore(
        new Date()
      ))

  useEffect(() => {
    setNumeroDeParcelas(1)
    setForm(prev => ({
      ...prev,
      numeroDeParcelas: 1
    }))
  }, [editCreditCard])

  if (editCreditCard)
    return (
      <>
        <TermosDeUso />
        <AlterarCartaoDeCredito
          form={form}
          setForm={setForm}
          setEditCreditCard={setEditCreditCard}
          novaAssinatura={novaAssinatura}
          precos={precos}
          assinaturaAtual={assinaturaAtual}
        />
        <Row
          type="flex"
          style={{
            flexDirection: 'column',
            rowGap: '20px',
            columnGap: '10px',
            marginBottom: '100px',
            marginTop: '20px'
          }}
        >
          <>
            <Text emphasys>
              Quais as formas de pagamento dos planos mensal e anual?
            </Text>
            <Text color="#949595">
              No plano mensal só aceitamos cartão de crédito como forma de
              pagamento. Enquanto no plano anual você pode pagar no cartão de
              crédito, PIX ou boleto bancário.
            </Text>
          </>
          {novaAssinatura?.intervalo === 'MENSAL' ? (
            <>
              <Text emphasys>
                Quais as vantagens de trocar o meu plano mensal para o anual ?{' '}
              </Text>
              <Text color="#949595">
                Migrando para o plano anual você economiza 20% no pagamento da
                assinatura. Você também garante o "congelamento" do preço pela
                duração do plano anual. Nenhum reajuste é aplicado durante o
                ciclo atual do plano atual. Independente disso, você também pode
                cancelar a qualquer momento para impedir que o plano seja
                renovado no futuro, se desejar.
              </Text>
            </>
          ) : (
            <>
              <Text emphasys>O que é a "Data da próxima cobrança"?</Text>
              <Text color="#949595">
                É a data em que seu plano será renovado e cobrado futuramente
                caso você não cancele sua assinatura. Porém você pode cancelar
                sua assinatura a qualquer momento para evitar renovações futuras
                em até 03 cliques dentro da sua própria plataforma. Veja como é
                fácil cancelar{' '}
                <a href="https://ajuda.previdenciarista.com/article/151-como-cancelar-minha-assinatura">
                  clicando aqui
                </a>
                .
              </Text>
            </>
          )}
          {novaAssinatura?.tiposDeModulo.length < 2 && (
            <>
              <Text emphasys>Turbine sua assinatura com módulos extras!</Text>
              <Text color="#949595">
                Você pode complementar sua assinatura com outros serviços extras
                do Previdenciarista. Confira os módulos acima e turbine sua
                assinatura para adquirir mais clientes e facilitar a rotina do
                seu escritório.
              </Text>
            </>
          )}
          {precos && precos.proRata && (
            <>
              <Text emphasys>O que é o desconto pró-rata?</Text>
              <Text color="#949595">
                No pró-rata calculamos o período que você já utilizou de um
                plano ou módulo, caso opte por alterar sua assinatura durante um
                período vigente e já pago anteriormente. Dessa forma você recebe
                o período não utilizado na forma de desconto.
              </Text>
              <>
                <Text emphasys>
                  Qual a diferença entre "Valor a ser cobrado agora" e "Valor da
                  próxima cobrança"?
                </Text>
                <Text color="#949595">
                  O "Valor a ser cobrado agora" é o valor que cobraremos nesse
                  momento na sua assinatura, podendo ser o valor integral de uma
                  assinatura caso você não esteja dentro de um período pago, ou
                  valores pró-rata referente a mudanças na assinatura como
                  acréscimos de módulos no período atual. O "Valor da próxima
                  cobrança" se refere ao valor dos próximos períodos, quando sua
                  assinatura se renovar automaticamente no futuro. Esse seria o
                  "valor cheio" do seu plano, sem descontos pró-rata aplicados.
                </Text>
              </>
            </>
          )}
        </Row>
      </>
    )

  return (
    <Col>
      <TermosDeUso />
      {meioDePagamento === 'CARTAO_DE_CREDITO' &&
        assinaturaAtual.assinatura.cartaoDeCredito && (
          <Col>
            <Text emphasys>Forma de pagamento</Text>
            <Row type="flex" justify="space-between" gutter={[16, 16]}>
              <Col span={24}>
                <Card
                  style={{
                    width: '100%'
                  }}
                  color="#F2F8FE"
                >
                  <Row style={{ justifyContent: 'space-between' }}>
                    <Col>
                      <Row
                        type="flex"
                        style={{ rowGap: '10px', columnGap: '10px' }}
                      >
                        <img
                          style={{ width: '20px' }}
                          src={
                            getBrandByCardNumber(
                              assinaturaAtual.assinatura.cartaoDeCredito
                                .primeirosSeisDigitos
                            ).image
                          }
                          alt="Bandeira do cartão"
                        />
                        <Text>
                          {assinaturaAtual.assinatura.cartaoDeCredito.bandeira}{' '}
                          **** **** ****{' '}
                          {
                            assinaturaAtual.assinatura.cartaoDeCredito
                              .ultimosQuatroDigitos
                          }
                        </Text>
                      </Row>
                      <Text color="#949595">
                        Data de validade:{' '}
                        <Text type="span" emphasys>
                          {assinaturaAtual.assinatura.cartaoDeCredito.dataDeVencimento.split(
                            '-'
                          )[1] +
                            '/' +
                            assinaturaAtual.assinatura.cartaoDeCredito.dataDeVencimento.split(
                              '-'
                            )[0]}
                        </Text>
                      </Text>
                    </Col>
                    <Button
                      id="pagamento_atual_editar"
                      data-testid="pagamento_atual_editar"
                      aria-label="Editar cartão de crédito"
                      style={{
                        backgroundColor: '#3575AB',
                        borderColor: '#3575AB'
                      }}
                      onClick={() => setEditCreditCard(true)}
                    >
                      Trocar cartão
                    </Button>
                  </Row>
                  {/* <Row style={{ marginTop: '10px' }}>
                    <Col>
                      {precos?.parcelas && regraMostrarModalConfirmaPagto && (
                        <SelectParcelas
                          precos={precos}
                          label="Parcelamento"
                          onChange={setNumeroDeParcelas}
                        />
                      )}
                    </Col>
                  </Row> */}
                </Card>
              </Col>
            </Row>
            <Button
              id="button_pagar"
              data-testid="button_pagar"
              aria-label="Pagar com cartão de crédito cadastrado"
              onClick={() => {
                if (regraMostrarModalConfirmaPagto) {
                  return Swal.fire({
                    title: 'Tem certeza?',
                    html: confirmModalText(
                      precos,
                      'CARTAO_DE_CREDITO',
                      assinaturaAtual,
                      numeroDeParcelas
                    ),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3575AB',
                    cancelButtonColor: '#d33',
                    confirmButtonText:
                      "<span data-testid='modal_confirmacao_sim'>Sim</span>",
                    cancelButtonText:
                      "<span data-testid='modal_confirmacao_nao'>Não</span>"
                  }).then(result => {
                    if (result.value) {
                      if (novaAssinatura.intervalo === 'ANUAL')
                        AnalyticsService.trackEvent(
                          'Confirmou alteração para o plano anual no cartão'
                        )
                      return postReativarPlano()
                    }
                    AnalyticsService.trackEvent(
                      `Negou alteração para o plano anual no cartão`
                    )
                  })
                }
                postReativarPlano()
              }}
              size="large"
              style={{ margin: '20px 0' }}
            >
              Pagar com cartão de crédito
            </Button>
          </Col>
        )}
      {meioDePagamento === 'CARTAO_DE_CREDITO' &&
        !assinaturaAtual.assinatura.cartaoDeCredito && (
          <Button
            size="large"
            style={{ margin: '20px 0' }}
            onClick={() => setEditCreditCard(true)}
          >
            Cadastrar novo cartão de crédito
          </Button>
        )}
      {meioDePagamento === 'PIX' && <AlterarPlanoPix />}
      {meioDePagamento === 'BOLETO' && <AlterarPlanoBoleto />}
      {novaAssinatura.intervalo === 'ANUAL' && (
        <Text emphasys>Outras formas de pagamento</Text>
      )}
      <Row type="flex" style={{ width: '100%' }}>
        {meioDePagamento !== 'PIX' && novaAssinatura.intervalo === 'ANUAL' && (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            span={12}
            style={{
              opacity: `${novaAssinatura.intervalo === 'MENSAL' ? 0.5 : 1}`
            }}
          >
            <Card
              style={{
                display: 'flex',
                alignItems: 'center',
                rowGap: '20px',
                columnGap: '20px',
                cursor: 'pointer',
                pointerEvents: novaAssinatura.intervalo === 'MENSAL'
              }}
              id="card_pagamento_pix"
              data-testid="card_pagamento_pix"
              aria-label="Selecionar pagamento via Pix"
              disabled={novaAssinatura.intervalo === 'MENSAL'}
              onClick={() => {
                if (regraMostrarModalConfirmaPagto) {
                  return Swal.fire({
                    title: 'Tem certeza?',
                    html: confirmModalText(precos, 'PIX', assinaturaAtual),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3575AB',
                    cancelButtonColor: '#d33',
                    confirmButtonText:
                      "<span data-testid='modal_confirmacao_sim'>Sim</span>",
                    cancelButtonText:
                      "<span data-testid='modal_confirmacao_nao'>Não</span>"
                  }).then(result => {
                    if (result.value) {
                      if (novaAssinatura.intervalo === 'ANUAL')
                        AnalyticsService.trackEvent(
                          'Confirmou alteração para o plano anual no PIX'
                        )
                      return setMeioDePagamento('PIX')
                    }
                    AnalyticsService.trackEvent(
                      `Negou alteração para o plano anual no PIX`
                    )
                  })
                }
                setMeioDePagamento('PIX')
              }}
            >
              <QrcodeOutlined style={{ fontSize: '25px' }} />
              <Text>Via pagamento instantaneo PIX</Text>
            </Card>
          </Col>
        )}
        {meioDePagamento !== 'BOLETO' &&
          novaAssinatura.intervalo === 'ANUAL' && (
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              span={12}
              style={{
                opacity: `${novaAssinatura.intervalo === 'MENSAL' ? 0.5 : 1}`
              }}
            >
              <Card
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  rowGap: '20px',
                  columnGap: '20px',
                  cursor: 'pointer',
                  pointerEvents: novaAssinatura.intervalo === 'MENSAL'
                }}
                id="card_pagamento_boleto"
                data-testid="card_pagamento_boleto"
                aria-label="Selecionar pagamento via boleto"
                disabled={novaAssinatura.intervalo === 'MENSAL'}
                onClick={() => {
                  if (regraMostrarModalConfirmaPagto) {
                    return Swal.fire({
                      title: 'Tem certeza?',
                      html: confirmModalText(precos, 'BOLETO', assinaturaAtual),
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3575AB',
                      cancelButtonColor: '#d33',
                      confirmButtonText:
                        "<span data-testid='modal_confirmacao_sim'>Sim</span>",
                      cancelButtonText:
                        "<span data-testid='modal_confirmacao_nao'>Não</span>"
                    }).then(result => {
                      if (result.value) {
                        if (novaAssinatura.intervalo === 'ANUAL')
                          AnalyticsService.trackEvent(
                            'Confirmou alteração para o plano anual no boleto'
                          )
                        return setMeioDePagamento('BOLETO')
                      }
                      AnalyticsService.trackEvent(
                        'Negou alteração para o plano anual no boleto'
                      )
                    })
                  }
                  setMeioDePagamento('BOLETO')
                }}
              >
                <BarcodeOutlined style={{ fontSize: '25px' }} />
                <Text>Via boleto bancário</Text>
              </Card>
            </Col>
          )}
        {meioDePagamento !== 'CARTAO_DE_CREDITO' && (
          <Col xs={24} sm={24} md={24} lg={12} xl={12} span={12}>
            <Card
              style={{
                display: 'flex',
                alignItems: 'center',
                rowGap: '20px',
                columnGap: '20px',
                cursor: 'pointer'
              }}
              onClick={() => setMeioDePagamento('CARTAO_DE_CREDITO')}
              id="card_pagamento_cartao"
              data-testid="card_pagamento_cartao"
              aria-label="Selecionar pagamento via cartao"
            >
              <CreditCardOutlined style={{ fontSize: '25px' }} />
              <Text>Via cartão de crédito</Text>
            </Card>
          </Col>
        )}
      </Row>
      <Row
        type="flex"
        style={{
          flexDirection: 'column',
          rowGap: '20px',
          columnGap: '10px',
          marginBottom: '100px'
        }}
      >
        <>
          <Text emphasys>
            Quais as formas de pagamento dos planos mensal e anual?
          </Text>
          <Text color="#949595">
            No plano mensal só aceitamos cartão de crédito como forma de
            pagamento. Enquanto no plano anual você pode pagar no cartão de
            crédito, PIX ou boleto bancário.
          </Text>
        </>
        {novaAssinatura?.intervalo === 'MENSAL' ? (
          <>
            <Text emphasys>
              Quais as vantagens de trocar o meu plano mensal para o anual ?{' '}
            </Text>
            <Text color="#949595">
              Migrando para o plano anual você economiza 20% no pagamento da
              assinatura. Você também garante o "congelamento" do preço pela
              duração do plano anual. Nenhum reajuste é aplicado durante o ciclo
              atual do plano atual. Independente disso, você também pode
              cancelar a qualquer momento para impedir que o plano seja renovado
              no futuro, se desejar.
            </Text>
          </>
        ) : (
          <>
            <Text emphasys>O que é a "Data da próxima cobrança"?</Text>
            <Text color="#949595">
              É a data em que seu plano será renovado e cobrado futuramente caso
              você não cancele sua assinatura. Porém você pode cancelar sua
              assinatura a qualquer momento para evitar renovações futuras em
              até 03 cliques dentro da sua própria plataforma. Veja como é fácil
              cancelar{' '}
              <a href="https://ajuda.previdenciarista.com/article/151-como-cancelar-minha-assinatura">
                clicando aqui
              </a>
              .
            </Text>
          </>
        )}
        {novaAssinatura?.tiposDeModulo.length < 2 && (
          <>
            <Text emphasys>Turbine sua assinatura com módulos extras!</Text>
            <Text color="#949595">
              Você pode complementar sua assinatura com outros serviços extras
              do Previdenciarista. Confira os módulos acima e turbine sua
              assinatura para adquirir mais clientes e facilitar a rotina do seu
              escritório.
            </Text>
          </>
        )}
        {precos && precos.proRata && (
          <>
            <Text emphasys>O que é o desconto pró-rata?</Text>
            <Text color="#949595">
              No pró-rata calculamos o período que você já utilizou de um plano
              ou módulo, caso opte por alterar sua assinatura durante um período
              vigente e já pago anteriormente. Dessa forma você recebe o período
              não utilizado na forma de desconto.
            </Text>
            <>
              <Text emphasys>
                Qual a diferença entre "Valor a ser cobrado agora" e "Valor da
                próxima cobrança"?
              </Text>
              <Text color="#949595">
                O "Valor a ser cobrado agora" é o valor que cobraremos nesse
                momento na sua assinatura, podendo ser o valor integral de uma
                assinatura caso você não esteja dentro de um período pago, ou
                valores pró-rata referente a mudanças na assinatura como
                acréscimos de módulos no período atual. O "Valor da próxima
                cobrança" se refere ao valor dos próximos períodos, quando sua
                assinatura se renovar automaticamente no futuro. Esse seria o
                "valor cheio" do seu plano, sem descontos pró-rata aplicados.
              </Text>
            </>
          </>
        )}
      </Row>
    </Col>
  )
}

export default PagamentoReativar
