import { Button, Card, Text } from "@prev/ui-kit";
import { Col, Row } from "antd";
import React, { useContext } from "react";
import { ResolvePendenciesContext } from "../../../context/resolverPendencias/ResolvePendenciesContext";
import useWindowSize from "../../../hooks/useWindowSize";
import { prevModules } from "../../../utils/prevModules";

function Resume() {
  const { modules, setHistModal, resume, assinaturaAtual } = useContext(ResolvePendenciesContext);
  const { width } = useWindowSize();

  const returnDomain = (intervalo = "ANUAL") => {
    window.location.href = `/plano/alterar?uuid=${resume.uuid}&intervalo=${intervalo}`;
    return;
  };

  if (resume && assinaturaAtual)
    return (
      <Card style={{ margin: 0, marginBottom: width < 1024 ? "20px" : 0 }}>
        <Text size="lg" style={{ paddingBottom: "15px" }} emphasys>
          Resumo da fatura
        </Text>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Text emphasys>Módulos</Text>
            {assinaturaAtual.assinatura.tiposDeModulo &&
              assinaturaAtual.assinatura.tiposDeModulo.map((e, index) => (
                <Row
                  key={`${new Date().getTime()}-${index}`}
                  type="flex"
                  justify="space-between"
                  style={{
                    rowGap: "15px",
                    columnGap: "15px",
                    marginTop: "10px",
                  }}
                >
                  <Row type="flex" style={{ rowGap: "15px", columnGap: "15px" }}>
                    {prevModules(e)}
                  </Row>
                </Row>
              ))}
          </Col>
          <Col span={12}>
            <Text emphasys>Fatura</Text>
            <Text color="#949595" id="fatura.uuidFatura" data-testid="fatura.uuidFatura">
              {resume.uuid}
            </Text>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Text emphasys>Valor Original</Text>
            <Text color="#949595" id="fatura.valorOriginalFatura" data-testid="fatura.valorOriginalFatura">
              {Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(resume.valorBase)}
            </Text>
          </Col>
          <Col span={12}>
            <Text emphasys>Descontos</Text>
            <Text id="fatura-desconto" data-testid="fatura-desconto" color="#949595">
              {Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(resume.valorDoDesconto)}
            </Text>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Text emphasys>Total da fatura</Text>
            <Text emphasys color="#949595" id="fatura.valorFatura" data-testid="fatura.valorFatura">
              {Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(resume.valor)}
            </Text>
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Button color="secondary" size="large" onClick={() => setHistModal(true)}>
              Ver histórico de tentativas
            </Button>
            {assinaturaAtual && assinaturaAtual.assinatura.intervalo !== "ANUAL" && (
              <Row type="flex" style={{ marginTop: "20px" }}>
                <Text>
                  Altere sua assinatura para o{" "}
                  <Text type="span" emphasys>
                    plano anual e ganhe 20% de desconto
                  </Text>
                  .{" "}
                  <Text type="a" style={{ cursor: "pointer" }} onClick={() => returnDomain()}>
                    Clique aqui e confira essa oferta!
                  </Text>
                </Text>
              </Row>
            )}

            {assinaturaAtual && assinaturaAtual.assinatura.intervalo === "ANUAL" && (
              <Row type="flex" style={{ marginTop: "20px" }}>
                <Text>
                  Caso queira alterar sua assinatura para o{` `}
                  <Text type="span" emphasys>
                    plano mensal
                  </Text>
                  ,{" "}
                  <Text type="a" style={{ cursor: "pointer" }} onClick={() => returnDomain("MENSAL")}>
                    clique aqui!
                  </Text>
                </Text>
              </Row>
            )}
          </Col>
        </Row>
      </Card>
    );
  return null;
}

export default Resume;
