import React, { useContext } from "react";
import { PageContainer } from "../../styles";
import { Button, Text } from "@prev/ui-kit";
import { Col, Row } from "antd";
import styled from "styled-components";
import failedIcon from "../../assets/images/failedIcon.svg";
import Header from "../../components/header/Header";

const StyledText = styled(Text)`
  color: #949595;
  font-weight: 600;
`;

export const ParcelamentoError = ({ errorMessage }) => {
  return (
    <PageContainer>
      <Header captionOff />
      <Row align="center" gutter={[0, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} span={24} style={{ textAlign: "center", alignItems: "center" }}>
          <img
            className="corners"
            style={{
              marginBottom: "20px",
            }}
            src={failedIcon}
            alt="Status icon"
            id="status-icon"
            data-testid="status-icon"
          />
          <Text
            id="erroPagamento-messsage"
            data-testid="erroPagamento-message"
            color="red"
            emphasys
            size="lg"
            style={{ marginBottom: "20px" }}
          >
            {errorMessage}
          </Text>
        </Col>
        <Button data-testid="bt-voltar" onClick={() => (window.location.href = "/configuracoes/financeiro")}>Voltar</Button>
      </Row>
    </PageContainer>
  );
};
