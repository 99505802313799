import {
  authenticatedGet,
  authenticatedPost,
  authenticatedPut,
  get,
  normalGet,
  normalPost,
  normalPut,
  withToken,
} from "./adapter";

import { API_HOST_DASHBOARD, API_HOST_V2 } from "../utils/constants";

export const obterFatura = async ({ tokenDaFatura }) =>
  normalGet({
    path: `/faturas/${tokenDaFatura}/dados-pagamento`,
    host: API_HOST_V2,
  });

export const pagarFatura = async ({ meioDePagamento, token, cartaoDeCredito }) =>
  normalPost({
    path: "/pagar-fatura-token",
    data: {
      meioDePagamento,
      token,
      cartaoDeCredito,
    },
    host: API_HOST_DASHBOARD,
  });

export const princing = async (body) =>
  normalPut({
    path: "/pricing",
    host: API_HOST_DASHBOARD,
    data: body,
  });

export const getAssinatura = withToken(
  async ({ token }) =>
    await get({
      path: `/info`,
      token,
    })
);

export const getStatus = async (uuidPagamento) => {
  const response = authenticatedGet({
    path: `/pagamentos/${uuidPagamento}/consulta-status`,
    host: API_HOST_DASHBOARD,
  });
  return response;
};

// RESOLVER PENDENCIAS

export const getFatura = async () => {
  const response = await authenticatedGet({
    path: `/assinatura/faturas`,
    host: API_HOST_DASHBOARD,
  });

  return response;
};

export const pagarPendencia = async (payload, token) => {
  const header = token
    ? {
        headers: {
          "g-recaptcha-response": token,
        },
      }
    : null;

  const response = authenticatedPost({
    path: `/assinatura/faturas/${payload.tokenFatura}/resolver-pendencia`,
    host: API_HOST_DASHBOARD,
    ...header,
    data: {
      ...payload,
      token: payload.tokenFatura,
    },
  });
  return response;
};

// ALTERAR PLANO

export const getInfo = async () => {
  const response = await authenticatedGet({
    path: `/info`,
    host: API_HOST_DASHBOARD,
  });
  return response;
};

export const getPricing = async (payload) => {
  const response = await authenticatedPut({
    path: `/pricing`,
    host: API_HOST_DASHBOARD,
    data: {
      ...payload,
    },
  });
  return response;
};

export const alterarPlano = async (payload, token) => {
  const header = token
    ? {
        headers: {
          "g-recaptcha-response": token,
        },
      }
    : null;

  const response = authenticatedPost({
    path: `/assinatura/plano`,
    host: API_HOST_DASHBOARD,
    ...header,
    data: {
      ...payload,
    },
  });
  return response;
};

// REATIVAR

export const reativarPlano = async (payload, token) => {
  const header = token
    ? {
        headers: {
          "g-recaptcha-response": token,
        },
      }
    : null;

  const response = authenticatedPost({
    path: `/assinatura/reativar`,
    host: API_HOST_DASHBOARD,
    ...header,
    data: {
      ...payload,
    },
  });
  return response;
};

export const alterarParcelamento = async ({ pricingUuid, qtdParcelas }) => {
  const response = authenticatedPut({
    path: `/assinaturas/parcelamento`,
    data: {
      pricingUuid,
      qtdParcelas,
    },
  });
  return response;
};
