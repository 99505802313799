import { Col } from "antd";
import styled from "styled-components";

export const BankSlipContainer = styled(Col)`
  display: flex;
  row-gap: 20px;
  column-gap: 20px;
  flex-direction: column;
  @media (max-width: 1023px) {
    order: 15;
  }
`;
