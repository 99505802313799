import React, { useContext } from "react";
import PaymentMethod from "../../../components/resolverPendencias/metodosDePagamento/MetodosDePagamento";
import { BankSlipContainer } from "./style";
import { BarcodeOutlined } from "@ant-design/icons";
import { Button, Text } from "@prev/ui-kit";
import { Col, Row } from "antd";
import { ResolvePendenciesContext } from "../../../context/resolverPendencias/ResolvePendenciesContext";
import useWindowSize from "../../../hooks/useWindowSize";

function BankSlip() {
  const { width } = useWindowSize();
  const { postPagarFatura } = useContext(ResolvePendenciesContext);

  return (
    <BankSlipContainer xs={24} sm={24} md={24} lg={14} xl={14} span={14}>
      <Col span={24} style={{ marginBottom: width > 1023 ? "70px" : "20px" }}>
        <Row style={{ width: "100%" }} gutter={[0, 40]}>
          <Text emphasys>Pagamento da fatura no boleto</Text>
          <Row gutter={[0, 16]}>
            <Text>
              A fatura só será paga após a{" "}
              <Text type="span" emphasys>
                compensação do boleto
              </Text>
              , caso queira a ativação instantânea, selecione o pagamento{" "}
              <Text type="span" emphasys>
                via cartão de crédito
              </Text>{" "}
              ou{" "}
              <Text type="span" emphasys>
                PIX
              </Text>
              .
            </Text>
            <Button
              id="btn-gerar-boleto"
              data-testid="btn-gerar-boleto"
              aria-label="Gerar boleto"
              onClick={() => postPagarFatura()}
              size="large"
              icon={<BarcodeOutlined />}
              style={{ width: width > 1023 ? "300px" : "100%" }}
            >
              Gerar boleto
            </Button>
          </Row>
        </Row>
      </Col>
      <PaymentMethod showTitle />
    </BankSlipContainer>
  );
}

export default BankSlip;
