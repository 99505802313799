import { Text } from '@prev/ui-kit'
import {
  FileSearchOutlined,
  BuildOutlined,
  CalculatorOutlined,
  AuditOutlined
} from '@ant-design/icons'

export const PREV_MODULES = {
  CATALOGO: (
    <>
      <FileSearchOutlined style={{ fontSize: '20px', color: '#3575ab' }} />
      <Text data-testid="futuro_modulo_diretorio">Diretório de advogados</Text>
    </>
  ),
  CALCULO_PETICOES: (
    <>
      <CalculatorOutlined style={{ fontSize: '20px', color: '#3575ab' }} />
      <Text data-testid="futuro_modulo_calculos">Cálculo de petições</Text>
    </>
  ),
  CASOS: (
    <>
      <BuildOutlined style={{ fontSize: '20px', color: '#3575ab' }} />
      <Text data-testid="futuro_modulo_casos">PrevCasos</Text>
    </>
  ),
  LAUDOS: (
    <>
      <AuditOutlined style={{ fontSize: '20px', color: '#3575ab' }} />
      <Text data-testid="futuro_modulo_laudos">Banco de Laudos</Text>
    </>
  )
}

export const prevModules = module => {
  if (module.includes('CASOS')) return PREV_MODULES.CASOS
  if (module.includes('LAUDOS')) return PREV_MODULES.LAUDOS
  return PREV_MODULES[module]
}

export const findModule = (module, tabelaPrecos) => {
  return tabelaPrecos?.find(modulo => modulo.tipo === module)
}

export const legendaCasos = (plano, minMax) => {
  if (!plano) return '0 casos/mês'
  if (plano.quantidadeMinimaUsos >= minMax.max) return 'Ilimitado'
  return `até ${plano.quantidadeMaximoUsos} casos/mês`
}

export const legendaLaudos = (plano, minMax) => {
  if (!plano) return '0 laudos/mês'
  if (plano.quantidadeMinimaUsos >= minMax.max) return 'Ilimitado'
  return `até ${plano.quantidadeMaximoUsos} laudos/mês`
}

export const betweenTwoValues = (value, min, max) => {
  return value >= min && value <= max
}
