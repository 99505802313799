import styled from "styled-components";

export const HeaderContainer = styled.nav`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: ${(props) => (props.captionOff ? "center" : "space-between")};
  align-items: center;
  max-width: 1280px;
  height: 50px;
  padding: 0;
  margin-bottom: ${(props) => (props.captionOff ? "40px" : "20px")};
`;
