import {
  Button,
  Card,
  getBrandByCardNumber,
  SegmentContext,
  Text
} from '@prev/ui-kit'
import { Col, Row } from 'antd'
import { useContext, useEffect, useState } from 'react'
import {
  BarcodeOutlined,
  CreditCardOutlined,
  QrcodeOutlined
} from '@ant-design/icons'
import Swal from 'sweetalert2'
import moment from 'moment'
import { equalsIgnoreOrder } from '../../utils/equalsArray'
import { confirmModalText } from '../../utils/confirmModalText'
import Pix from './Pix'
import TermosDeUso from '../termosDeUso/TermosDeUso'
import Boleto from './Boleto'
import { PlanoContext } from '../../context/plano/PlanoContext'
import AlterarCartaoDeCredito from './CartaoDeCredito'
import { SelectParcelas } from './SelectParcelas'

const Pagamento = () => {
  const {
    assinaturaAtual,
    meioDePagamento,
    postAlterarPlano,
    setMeioDePagamento,
    novaAssinatura,
    precos,
    AnalyticsService,
    numeroDeParcelas,
    setNumeroDeParcelas,
    carregandoPreco
  } = useContext(PlanoContext)
  const [editCreditCard, setEditCreditCard] = useState(false)
  const [form, setForm] = useState()
  const { analytics } = useContext(SegmentContext)

  useEffect(() => {
    setNumeroDeParcelas(1)
    setForm(prev => ({
      ...prev,
      numeroDeParcelas: 1
    }))
  }, [editCreditCard])

  const regraMostrarModalConfirmaPagto =
    novaAssinatura.intervalo === 'ANUAL' &&
    precos &&
    (precos.proRata ||
      moment(precos.assinatura.periodo.dataDaCobranca).isSameOrBefore(
        new Date()
      ))

  const isDataPagamentoFuturo = moment(
    precos.assinatura.periodo.dataDaCobranca
  ).isAfter(new Date())

  if (editCreditCard)
    return (
      <>
        <TermosDeUso />
        <AlterarCartaoDeCredito
          form={form}
          setForm={setForm}
          setEditCreditCard={setEditCreditCard}
          novaAssinatura={novaAssinatura}
          precos={precos}
          assinaturaAtual={assinaturaAtual}
        />
        <Row
          type="flex"
          style={{
            flexDirection: 'column',
            rowGap: '20px',
            columnGap: '10px',
            marginBottom: '100px',
            marginTop: '20px'
          }}
        >
          <>
            <Text emphasys>
              Quais as formas de pagamento dos planos mensal e anual?
            </Text>
            <Text color="#949595">
              No plano mensal só aceitamos cartão de crédito como forma de
              pagamento. Enquanto no plano anual você pode pagar no cartão de
              crédito, PIX ou boleto bancário. No anual com cartão você ainda
              pode parcelar em até 6x parcelas com juros.
            </Text>
          </>
          {novaAssinatura?.intervalo === 'MENSAL' ? (
            <>
              <Text emphasys>
                Quais as vantagens de trocar o meu plano mensal para o anual ?{' '}
              </Text>
              <Text color="#949595">
                Migrando para o plano anual você economiza 20% no pagamento da
                assinatura. Você também garante o "congelamento" do preço pela
                duração do plano anual. Nenhum reajuste é aplicado durante o
                ciclo atual do plano atual. Independente disso, você também pode
                cancelar a qualquer momento para impedir que o plano seja
                renovado no futuro, se desejar.
              </Text>
            </>
          ) : (
            <>
              <Text emphasys>O que é a "Data da próxima cobrança"?</Text>
              <Text color="#949595">
                É a data em que seu plano será renovado e cobrado futuramente
                caso você não cancele sua assinatura. Porém você pode cancelar
                sua assinatura a qualquer momento para evitar renovações futuras
                em até 03 cliques dentro da sua própria plataforma. Veja como é
                fácil cancelar{' '}
                <a href="https://ajuda.previdenciarista.com/article/151-como-cancelar-minha-assinatura">
                  clicando aqui
                </a>
                .
              </Text>
            </>
          )}
          {novaAssinatura?.tiposDeModulo.length < 2 && (
            <>
              <Text emphasys>Turbine sua assinatura com módulos extras!</Text>
              <Text color="#949595">
                Você pode complementar sua assinatura com outros serviços extras
                do Previdenciarista. Confira os módulos acima e turbine sua
                assinatura para adquirir mais clientes e facilitar a rotina do
                seu escritório.
              </Text>
            </>
          )}
          {precos && precos.proRata && (
            <>
              <Text emphasys>O que é o desconto pró-rata?</Text>
              <Text color="#949595">
                No pró-rata calculamos o período que você já utilizou de um
                plano ou módulo, caso opte por alterar sua assinatura durante um
                período vigente e já pago anteriormente. Dessa forma você recebe
                o período não utilizado na forma de desconto.
              </Text>
              <>
                <Text emphasys>
                  Qual a diferença entre "Valor a ser cobrado agora" e "Valor da
                  próxima cobrança"?
                </Text>
                <Text color="#949595">
                  O "Valor a ser cobrado agora" é o valor que cobraremos nesse
                  momento na sua assinatura, podendo ser o valor integral de uma
                  assinatura caso você não esteja dentro de um período pago, ou
                  valores pró-rata referente a mudanças na assinatura como
                  acréscimos de módulos no período atual. O "Valor da próxima
                  cobrança" se refere ao valor dos próximos períodos, quando sua
                  assinatura se renovar automaticamente no futuro. Esse seria o
                  "valor cheio" do seu plano, sem descontos pró-rata aplicados.
                </Text>
              </>
            </>
          )}
        </Row>
      </>
    )

  return (
    <Col>
      <TermosDeUso />
      {meioDePagamento === 'CARTAO_DE_CREDITO' &&
        assinaturaAtual.assinatura.cartaoDeCredito && (
          <Col>
            <Text emphasys>Forma de pagamento</Text>
            <Row type="flex" justify="space-between" gutter={[16, 16]}>
              <Col span={24}>
                <Card
                  style={{
                    width: '100%'
                  }}
                  color="#F2F8FE"
                >
                  <Row style={{ justifyContent: 'space-between' }}>
                    <Col>
                      <Row
                        type="flex"
                        style={{ rowGap: '10px', columnGap: '10px' }}
                      >
                        <img
                          style={{ width: '20px' }}
                          src={
                            getBrandByCardNumber(
                              assinaturaAtual.assinatura.cartaoDeCredito
                                .primeirosSeisDigitos
                            ).image
                          }
                          alt="Bandeira do cartão"
                        />
                        <Text>
                          {assinaturaAtual.assinatura.cartaoDeCredito.bandeira}{' '}
                          **** **** ****{' '}
                          {
                            assinaturaAtual.assinatura.cartaoDeCredito
                              .ultimosQuatroDigitos
                          }
                        </Text>
                      </Row>
                      <Text color="#949595">
                        Data de validade:{' '}
                        <Text type="span" emphasys>
                          {assinaturaAtual.assinatura.cartaoDeCredito.dataDeVencimento.split(
                            '-'
                          )[1] +
                            '/' +
                            assinaturaAtual.assinatura.cartaoDeCredito.dataDeVencimento.split(
                              '-'
                            )[0]}
                        </Text>
                      </Text>
                    </Col>
                    <Button
                      id="pagamento_atual_editar"
                      data-testid="pagamento_atual_editar"
                      aria-label="Editar cartão de crédito"
                      style={{
                        backgroundColor:
                          equalsIgnoreOrder(
                            assinaturaAtual.assinatura.tiposDeModulo,
                            novaAssinatura.tiposDeModulo
                          ) &&
                            assinaturaAtual.assinatura.intervalo ===
                            novaAssinatura.intervalo
                            ? '#EEEEEE'
                            : '#3575AB',
                        borderColor:
                          equalsIgnoreOrder(
                            assinaturaAtual.assinatura.tiposDeModulo,
                            novaAssinatura.tiposDeModulo
                          ) &&
                            assinaturaAtual.assinatura.intervalo ===
                            novaAssinatura.intervalo
                            ? '#EEEEEE'
                            : '#3575AB'
                      }}
                      onClick={() => setEditCreditCard(true)}
                      disabled={
                        equalsIgnoreOrder(
                          assinaturaAtual.assinatura.tiposDeModulo,
                          novaAssinatura.tiposDeModulo
                        ) &&
                        assinaturaAtual.assinatura.intervalo ===
                        novaAssinatura.intervalo
                      }
                    >
                      Trocar cartão
                    </Button>
                  </Row>
                  <Row style={{ marginTop: '10px' }}>
                    <Col>
                      {precos?.parcelas &&
                        !equalsIgnoreOrder(
                          assinaturaAtual.assinatura.tiposDeModulo,
                          novaAssinatura.tiposDeModulo
                        ) &&
                        novaAssinatura.intervalo !== 'MENSAL' && (
                          <SelectParcelas
                            precos={precos}
                            label="Parcelamento"
                            numeroDeParcelas={numeroDeParcelas}
                            onChange={setNumeroDeParcelas}
                            id="parcelamento-options"
                            aria-label="Opções de parcelamento"
                            data-testid="select-numero-parcelas"
                          />
                        )}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Button
              id="button_pagar"
              data-testid="button_pagar"
              aria-label="Pagar com cartão de crédito cadastrado"
              onClick={() => {
                if (regraMostrarModalConfirmaPagto) {
                  return Swal.fire({
                    title: 'Tem certeza?',
                    html: confirmModalText(
                      precos,
                      'CARTAO_DE_CREDITO',
                      assinaturaAtual,
                      numeroDeParcelas
                    ),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3575AB',
                    cancelButtonColor: '#d33',
                    confirmButtonText:
                      "<span data-testid='modal_confirmacao_sim'>Sim</span>",
                    cancelButtonText:
                      "<span data-testid='modal_confirmacao_nao'>Não</span>"
                  }).then(result => {
                    if (result.value) {
                      if (novaAssinatura.intervalo === 'ANUAL')
                        AnalyticsService.trackEvent(
                          'Confirmou alteração para o plano anual no cartão'
                        )
                      return postAlterarPlano()
                    }
                    AnalyticsService.trackEvent(
                      `Negou alteração para o plano anual no cartão`
                    )
                  })
                }
                postAlterarPlano()
              }}
              size="large"
              style={{ margin: '20px 0' }}
              disabled={
                (equalsIgnoreOrder(
                  assinaturaAtual.assinatura.tiposDeModulo,
                  novaAssinatura.tiposDeModulo
                ) &&
                  assinaturaAtual.assinatura.intervalo ===
                  novaAssinatura.intervalo) ||
                carregandoPreco
              }
            >
              {isDataPagamentoFuturo
                ? `Atualizar Plano`
                : `Pagar com cartão de crédito`}
            </Button>
          </Col>
        )}
      {meioDePagamento === 'CARTAO_DE_CREDITO' &&
        !assinaturaAtual.assinatura.cartaoDeCredito && (
          <Button
            size="large"
            style={{ margin: '20px 0' }}
            onClick={() => setEditCreditCard(true)}
            disabled={
              equalsIgnoreOrder(
                assinaturaAtual.assinatura.tiposDeModulo,
                novaAssinatura.tiposDeModulo
              ) &&
              novaAssinatura.intervalo === assinaturaAtual.assinatura.intervalo
            }
          >
            Cadastrar novo cartão de crédito e pagar
          </Button>
        )}
      {meioDePagamento === 'PIX' && <Pix />}
      {meioDePagamento === 'BOLETO' && <Boleto />}
      {novaAssinatura.intervalo === 'ANUAL' && (
        <Text emphasys>Outras formas de pagamento</Text>
      )}
      <Row type="flex" style={{ width: '100%' }}>
        {meioDePagamento !== 'PIX' && novaAssinatura.intervalo === 'ANUAL' && (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            span={12}
            style={{
              opacity: `${(!equalsIgnoreOrder(
                assinaturaAtual.assinatura.tiposDeModulo,
                novaAssinatura.tiposDeModulo
              ) ||
                  (novaAssinatura.intervalo !== 'MENSAL' &&
                    assinaturaAtual.assinatura.intervalo === 'MENSAL')) &&
                  novaAssinatura.intervalo !== 'MENSAL'
                  ? 1
                  : 0.5
                }`
            }}
          >
            <Card
              id="card_pagamento_pix"
              data-testid="card_pagamento_pix"
              aria-label="Selecionar pagamento via Pix"
              disabled={
                (!equalsIgnoreOrder(
                  assinaturaAtual.assinatura.tiposDeModulo,
                  novaAssinatura.tiposDeModulo
                ) ||
                  (novaAssinatura.intervalo !== 'MENSAL' &&
                    assinaturaAtual.assinatura.intervalo === 'MENSAL')) &&
                  novaAssinatura.intervalo !== 'MENSAL'
                  ? false
                  : true
              }
              onClick={() => {
                if (regraMostrarModalConfirmaPagto) {
                  return Swal.fire({
                    title: 'Tem certeza?',
                    html: confirmModalText(precos, 'PIX', assinaturaAtual),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3575AB',
                    cancelButtonColor: '#d33',
                    confirmButtonText:
                      "<span data-testid='modal_confirmacao_sim'>Sim</span>",
                    cancelButtonText:
                      "<span data-testid='modal_confirmacao_nao'>Não</span>"
                  }).then(result => {
                    if (result.value) {
                      if (novaAssinatura.intervalo === 'ANUAL')
                        AnalyticsService.trackEvent(
                          'Confirmou alteração para o plano anual no PIX'
                        )
                      return setMeioDePagamento('PIX')
                    }
                    AnalyticsService.trackEvent(
                      `Negou alteração para o plano anual no PIX`
                    )
                  })
                }
                setMeioDePagamento('PIX')
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                rowGap: '20px',
                columnGap: '20px',
                cursor: 'pointer',
                pointerEvents:
                  (!equalsIgnoreOrder(
                    assinaturaAtual.assinatura.tiposDeModulo,
                    novaAssinatura.tiposDeModulo
                  ) ||
                    (novaAssinatura.intervalo !== 'MENSAL' &&
                      assinaturaAtual.assinatura.intervalo === 'MENSAL')) &&
                    novaAssinatura.intervalo !== 'MENSAL'
                    ? 'default'
                    : 'none'
              }}
            >
              <QrcodeOutlined style={{ fontSize: '25px' }} />
              <Text>Via pagamento instantaneo PIX</Text>
            </Card>
          </Col>
        )}
        {meioDePagamento !== 'BOLETO' &&
          novaAssinatura.intervalo === 'ANUAL' && (
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              span={12}
              style={{
                opacity: `${(!equalsIgnoreOrder(
                  assinaturaAtual.assinatura.tiposDeModulo,
                  novaAssinatura.tiposDeModulo
                ) ||
                    (novaAssinatura.intervalo !== 'MENSAL' &&
                      assinaturaAtual.assinatura.intervalo === 'MENSAL')) &&
                    novaAssinatura.intervalo !== 'MENSAL'
                    ? 1
                    : 0.5
                  }`
              }}
            >
              <Card
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  rowGap: '20px',
                  columnGap: '20px',
                  cursor: 'pointer',
                  width: '100%',
                  pointerEvents:
                    (!equalsIgnoreOrder(
                      assinaturaAtual.assinatura.tiposDeModulo,
                      novaAssinatura.tiposDeModulo
                    ) ||
                      (novaAssinatura.intervalo !== 'MENSAL' &&
                        assinaturaAtual.assinatura.intervalo === 'MENSAL')) &&
                      novaAssinatura.intervalo !== 'MENSAL'
                      ? 'default'
                      : 'none'
                }}
                id="card_pagamento_boleto"
                data-testid="card_pagamento_boleto"
                aria-label="Selecionar pagamento via boleto"
                disabled={
                  (!equalsIgnoreOrder(
                    assinaturaAtual.assinatura.tiposDeModulo,
                    novaAssinatura.tiposDeModulo
                  ) ||
                    (novaAssinatura.intervalo !== 'MENSAL' &&
                      assinaturaAtual.assinatura.intervalo === 'MENSAL')) &&
                    novaAssinatura.intervalo !== 'MENSAL'
                    ? false
                    : true
                }
                onClick={() => {
                  if (regraMostrarModalConfirmaPagto) {
                    return Swal.fire({
                      title: 'Tem certeza?',
                      html: confirmModalText(precos, 'BOLETO', assinaturaAtual),
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3575AB',
                      cancelButtonColor: '#d33',
                      confirmButtonText:
                        "<span data-testid='modal_confirmacao_sim'>Sim</span>",
                      cancelButtonText:
                        "<span data-testid='modal_confirmacao_nao'>Não</span>"
                    }).then(result => {
                      if (result.value) {
                        if (novaAssinatura.intervalo === 'ANUAL')
                          AnalyticsService.trackEvent(
                            'Confirmou alteração para o plano anual no boleto'
                          )
                        return setMeioDePagamento('BOLETO')
                      }
                      AnalyticsService.trackEvent(
                        'Negou alteração para o plano anual no boleto'
                      )
                    })
                  }
                  setMeioDePagamento('BOLETO')
                }}
              >
                <BarcodeOutlined style={{ fontSize: '25px' }} />
                <Text>Via boleto bancário</Text>
              </Card>
            </Col>
          )}
        {meioDePagamento !== 'CARTAO_DE_CREDITO' && (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            span={12}
            style={{
              opacity: `${equalsIgnoreOrder(
                assinaturaAtual.assinatura.tiposDeModulo,
                novaAssinatura.tiposDeModulo
              ) &&
                  assinaturaAtual.assinatura.intervalo ===
                  novaAssinatura.intervalo
                  ? 0.5
                  : 1
                }`
            }}
          >
            <button
              style={{
                width: '100%',
                display: 'flex',
                border: 'none',
                background: 'none'
              }}
              disabled={
                equalsIgnoreOrder(
                  assinaturaAtual.assinatura.tiposDeModulo,
                  novaAssinatura.tiposDeModulo
                ) &&
                assinaturaAtual.assinatura.intervalo ===
                novaAssinatura.intervalo
              }
              id="card_pagamento_cartao"
              data-testid="card_pagamento_cartao"
              aria-label="Selecionar pagamento via cartao"
              onClick={() => setMeioDePagamento('CARTAO_DE_CREDITO')}
            >
              <Card
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  rowGap: '20px',
                  columnGap: '20px',
                  cursor: 'pointer'
                }}
              >
                <CreditCardOutlined style={{ fontSize: '25px' }} />
                <Text>Via cartão de crédito</Text>
              </Card>
            </button>
          </Col>
        )}
      </Row>
      <Row
        type="flex"
        style={{
          flexDirection: 'column',
          rowGap: '20px',
          columnGap: '10px',
          marginBottom: '100px'
        }}
      >
        <>
          <Text emphasys>
            Quais as formas de pagamento dos planos mensal e anual?
          </Text>
          <Text color="#949595">
            No plano mensal só aceitamos cartão de crédito como forma de
            pagamento. Enquanto no plano anual você pode pagar no cartão de
            crédito, PIX ou boleto bancário.
          </Text>
        </>
        {novaAssinatura?.intervalo === 'MENSAL' ? (
          <>
            <Text emphasys>
              Quais as vantagens de trocar o meu plano mensal para o anual ?{' '}
            </Text>
            <Text color="#949595">
              Migrando para o plano anual você economiza 20% no pagamento da
              assinatura. Você também garante o "congelamento" do preço pela
              duração do plano anual. Nenhum reajuste é aplicado durante o ciclo
              atual do plano atual. Independente disso, você também pode
              cancelar a qualquer momento para impedir que o plano seja renovado
              no futuro, se desejar.
            </Text>
          </>
        ) : (
          <>
            <Text emphasys>O que é a "Data da próxima cobrança"?</Text>
            <Text color="#949595">
              É a data em que seu plano será renovado e cobrado futuramente caso
              você não cancele sua assinatura. Porém você pode cancelar sua
              assinatura a qualquer momento para evitar renovações futuras em
              até 03 cliques dentro da sua própria plataforma. Veja como é fácil
              cancelar{' '}
              <a href="https://ajuda.previdenciarista.com/article/151-como-cancelar-minha-assinatura">
                clicando aqui
              </a>
              .
            </Text>
          </>
        )}
        {novaAssinatura?.tiposDeModulo.length < 2 && (
          <>
            <Text emphasys>Turbine sua assinatura com módulos extras!</Text>
            <Text color="#949595">
              Você pode complementar sua assinatura com outros serviços extras
              do Previdenciarista. Confira os módulos acima e turbine sua
              assinatura para adquirir mais clientes e facilitar a rotina do seu
              escritório.
            </Text>
          </>
        )}
        {precos && precos.proRata && (
          <>
            <Text emphasys>O que é o desconto pró-rata?</Text>
            <Text color="#949595">
              No pró-rata calculamos o período que você já utilizou de um plano
              ou módulo, caso opte por alterar sua assinatura durante um período
              vigente e já pago anteriormente. Dessa forma você recebe o período
              não utilizado na forma de desconto.
            </Text>
            <>
              <Text emphasys>
                Qual a diferença entre "Valor a ser cobrado agora" e "Valor da
                próxima cobrança"?
              </Text>
              <Text color="#949595">
                O "Valor a ser cobrado agora" é o valor que cobraremos nesse
                momento na sua assinatura, podendo ser o valor integral de uma
                assinatura caso você não esteja dentro de um período pago, ou
                valores pró-rata referente a mudanças na assinatura como
                acréscimos de módulos no período atual. O "Valor da próxima
                cobrança" se refere ao valor dos próximos períodos, quando sua
                assinatura se renovar automaticamente no futuro. Esse seria o
                "valor cheio" do seu plano, sem descontos pró-rata aplicados.
              </Text>
            </>
          </>
        )}
      </Row>
    </Col>
  )
}

export default Pagamento
